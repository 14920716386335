import React from "react";
import { Link } from "react-router-dom";
// import Loader from "./Loader";
// import courses from "../../courses";
import Course from "./Course";
// import { useGetCoursesQuery } from "../../slice/coursesApiSlice";

const TopCourse = ({courses}) => {

  return (
    <div className="top-course">
      <div className="container">
        <h3 className="section-title text-start">Top Courses</h3>
        <div className="row g-4">
          {courses.map((course) => (
            <Course key={course.id} course={course} />
          ))}
          <nav aria-label="Course pagination">
            <ul className="pagination justify-content-center">
              <li className="page-item disabled prev">
                <Link className="page-link" to="/" tabIndex={-1}>
                  «
                </Link>
              </li>
              <li className="page-item active">
                <Link className="page-link" to="/">
                  1
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="/">
                  2
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="/">
                  3
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="/">
                  4
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="/">
                  5
                </Link>
              </li>
              <li className="page-item next">
                <Link className="page-link" to="/">
                  »
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TopCourse;
