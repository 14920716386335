import React, { useState } from "react";
import { Container, FormGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import courseImg from "../../assets/img/course1.jpg";
import styles from "./CheckOut.module.css";

function CheckOut() {
  const [selectedOption, setSelectedOption] = useState("");
  const [CBEInputVisible, setCBEInputVisible] = useState(false);
  const [paypalInputVisible, setPaypalInputVisible] = useState(false);
  const [awashInputVisible, setAwashInputVisible] = useState(false);
  const [telebirrInputVisible, setTelebirrInputVisible] = useState(false);

  const handleRadioChange = (event) => {
    const { value } = event.target;

    setSelectedOption(value);

    if (value === "cbe birr") {
      setCBEInputVisible(true);
    } else {
      setCBEInputVisible(false);
    }

    if (value === "paypal") {
      setPaypalInputVisible(true);
    } else {
      setPaypalInputVisible(false);
    }
    if (value === "awash") {
      setAwashInputVisible(true);
    } else {
      setAwashInputVisible(false);
    }
    if (value === "telebirr") {
      setTelebirrInputVisible(true);
    } else {
      setTelebirrInputVisible(false);
    }
  };

  return (
    <div className="bg-white p-5">
      <Container>
        <h1 className="fw-bolder py-4"> CheckOut </h1>
        <p className="fw-bold fs-5"> Billing address</p>
        <p className="mb-1">Country</p>
        <div className="d-inline-block">
          <Form.Select
            className="py-2 px-5"
            aria-label="Default select example"
          >
            <option>Select country </option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Somalia">Somalia</option>
            <option value="Kenya">Kenya</option>
          </Form.Select>
        </div>

        <p className="fs-5 fw-bold mt-5">Payment method</p>

        <FormGroup className="">
          <Form.Check
            className={`border px-5 py-2 mb-0 ${styles.darkbackground} `}
            type="radio"
            id={`default-radio`}
            label={`CBE Birr`}
            value={"cbe birr"}
            checked={selectedOption === "cbe birr"}
            onChange={handleRadioChange}
          />

          {CBEInputVisible && (
            <div className="p-5 border bg-white">
              <p>this is input page for CBE birr payment method </p>
            </div>
          )}

          <Form.Check
            className={`border px-5 py-2 mb-0 ${styles.darkbackground} `}
            type="radio"
            id={`default-radio`}
            label={`Telebirr`}
            value={"telebirr"}
            checked={selectedOption === "telebirr"}
            onChange={handleRadioChange}
          />

          {telebirrInputVisible && (
            <div className="p-5 border bg-white">
              <p>this is input page for tele birr payment method </p>
            </div>
          )}

          <Form.Check
            className={`border px-5 py-2 mb-0 ${styles.darkbackground} `}
            type="radio"
            id={`default-radio`}
            label={`Awash bank`}
            value={"awash"}
            checked={selectedOption === "awash"}
            onChange={handleRadioChange}
          />

          {awashInputVisible && (
            <div className="p-5 border bg-white ">
              <p>this is input page for awash payment method </p>
            </div>
          )}

          <Form.Check
            className={`border px-5 py-2 mb-0 ${styles.darkbackground} `}
            type="radio"
            id={`default-radio`}
            label={`Paypal`}
            value={"paypal"}
            checked={selectedOption === "paypal"}
            onChange={handleRadioChange}
          />

          {paypalInputVisible && (
            <div className="p-5 border bg-white">
              <p>this is input page for paypal payment method </p>
            </div>
          )}
        </FormGroup>

        {CBEInputVisible && <div> this is cbe page</div>}

        <p className="fs-5 fw-bold mt-4">Order detail</p>
        <div className="d-flex border-top py-4 justify-content-between">
          <div className="container-fluid d-flex justify-content-between ">
            <div className="d-flex">
              <img src={courseImg} className={styles.Img} alt="." />

              <div className="">
                <h6 className={`fs-5  my-1 `}>
                  {" "}
                  MERN From Scratch 2023 | eCommerce Platform{" "}
                </h6>
                <p class={`opacity-50 my-1 $ `}> By Brand Traversy </p>
              </div>
            </div>
          </div>

          <div className="px-3">
            <p className="text-warning fs-6 fw-bold p-0  m-0 text-nowrap">
              {" "}
              15.99 Birr
            </p>
            <p className="opacity-50 text-decoration-line-through p-0 m-0">
              {" "}
              74.99 Birr
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CheckOut;
