import React from "react";
import { Link, useParams } from "react-router-dom";
import instImg from "../assets/img/course1.jpg";
import coursePreview from "../assets/videos/AdobeStock_194449450_Video_HD_Preview.mp4";
import { useGetCourseDetailsQuery } from "../slice/coursesApiSlice";

import Navbar from "../components/home/Navbar";
import Footer from "../components/home/Footer";
import Loader from "../components/home/Loader";
const CourseDetails = () => {
  const userHasPurchased = false;
  const { id: courseId } = useParams();

  const {
    data: course,
    isLoading,
    isError,
  } = useGetCourseDetailsQuery(courseId);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Loader />;
  }

  if (!course || !course) {
    return <Loader />;
  }

  console.log(course.data.course);
  return (
    <>
      <Navbar />
      <div className="course-details">
        <div className="container all-course-details-container">
          <h5 className="course-title fw-bolder">
            Adobe Xd {course.data.course.id}
          </h5>
          <div className="instructor-details row">
            <div className="col-lg-4">
              <img src={instImg} alt="no" />{" "}
              <span className="fw-bold">
                A course by {course.data.course.instructor.name}
              </span>
            </div>
          </div>
          <div className="all-about-course row justify-content-center align-items-center p-2">
            <div className="course-preview row gx-5">
              <video controls className="col-lg-6">
                <source src={coursePreview} />
              </video>
              <div className="col-lg-6 mt-3">
                <h5 className="fw-bolder">Course Features</h5>
                <ul>
                  <li>
                    <i className="fas fa-star me-1" style={{ color: '#F6C90E' }}/> Level: Beginner
                  </li>
                  <li>
                    <i className="fas fa-language me-1" style={{ color: '#F6C90E' }}/> Language: አማርኛ
                  </li>
                  <li>
                    <i className="fas fa-closed-captioning me-1" style={{ color: '#F6C90E' }}/> Subtitle: አማርኛ, English
                  </li>
                  <li>
                    <i className="fas fa-clock me-1" style={{ color: '#F6C90E' }}/> Duration: 4:00 h
                  </li>
                  <li>
                    <i className="fas fa-graduation-cap me-1" style={{ color: '#F6C90E' }}/> Lessons: 58
                  </li>
                  <li>
                    <i className="fas fa-file me-1" style={{ color: '#F6C90E' }}/> Resources: 12
                  </li>
                  <li>
                    <i className="fas fa-question-circle me-1" style={{ color: '#F6C90E' }}/> Quiz: 8
                  </li>
                  <li>
                    <i className="fas fa-mobile me-1 text-nowrap" style={{ color: '#F6C90E' }}/> Access on mobile and desktop
                  </li>
                </ul>
              </div>
            </div>
            <div className="course-req-content row justify-content-center align-items-center ms-4">
              <div className="col-lg-12 course-requirement">
                <div className="sec-title">
                  <h5 className="fw-bolder">Course Requirement</h5>
                </div>
                <ul className="req-list">
                  <li className="req-item">
                    Access to computer and internet connection
                  </li>
                  <li className="req-item">
                    Access to computer and internet connection
                  </li>
                  <li className="req-item">
                    Access to computer and internet connection
                  </li>
                </ul>
              </div>
            </div>
            <div className="course-contents ms-4 w-100">
              <div className="container">
                <h5 className="mb-3 ms-2 fw-bolder">Course Contents</h5>
                <div id="purchasedCourseAccordion">
                  <div className="all-unit-container">
                    <div className="card ">
                      <div className="card-header" id="lesson-1-heading">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link text-decoration-none"
                            data-toggle="collapse"
                            data-target="#lesson-1"
                            aria-expanded="true"
                            aria-controls="lesson-1"
                          >
                            {`Lesson 1: Introduction`}
                            {!userHasPurchased && (
                              <span className="locked-icon"> 🔒</span>
                            )}
                          </button>
                        </h5>
                      </div>

                      <div
                        id="lesson-1"
                        className="collapse"
                        aria-labelledby="lesson-1-heading"
                        data-parent="#purchasedCourseAccordion"
                      >
                        <div className="card-body">
                          {userHasPurchased ? (
                            <video controls className="w-100">
                              <source src={coursePreview} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <div className="locked-message">
                              <div className="add-to-cart row">
                                <div className="purchase-first col-lg-6 mt-2">
                                  <p>To unlock the contents purchase first</p>
                                </div>
                                <button className="add-to-cart-btn col-lg-2 col-sm-4 col-xl-2 col-md-4 text-nowrap btn">
                                  Add to cart
                                  <span className="cart-icon">
                                    <i className="fas fa-cart-shopping"></i>
                                  </span>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Lesson 2 */}
                    <div className="card ">
                      <div className="card-header" id="lesson-2-heading">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link text-decoration-none"
                            data-toggle="collapse"
                            data-target="#lesson-2"
                            aria-expanded="false"
                            aria-controls="lesson-2"
                          >
                            {`Lesson 2: Installing Adobe XD `}
                            {!userHasPurchased && (
                              <span className="locked-icon"> 🔒</span>
                            )}
                          </button>
                        </h5>
                      </div>

                      <div
                        id="lesson-2"
                        className="collapse"
                        aria-labelledby="lesson-2-heading"
                        data-parent="#purchasedCourseAccordion"
                      >
                        <div className="card-body">
                          {userHasPurchased ? (
                            <video controls className="w-100">
                              <source src={coursePreview} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <div className="locked-message">
                              <div className="add-to-cart row">
                                <div className="purchase-first col-lg-6 mt-2">
                                  <p>To unlock the contents purchase first</p>
                                </div>
                                <button className="add-to-cart-btn col-lg-2 col-sm-4 col-xl-2 col-md-4 text-nowrap btn">
                                  Add to cart
                                  <span className="cart-icon">
                                    <i className="fas fa-cart-shopping"></i>
                                  </span>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="add-to-cart d-flex justify-content-center align-items-center mb-4">
                      <button className="add-to-cart-btn btn">
                        Add to cart
                        <span className="cart-icon">
                          <i className="fas fa-cart-shopping"></i>
                        </span>
                      </button>
                    </div>
                    <nav aria-label="Lesson pagination">
                      <ul className="pagination justify-content-center">
                        <li className="page-item disabled prev">
                          <Link className="page-link" href="." tabIndex={-1}>
                            «
                          </Link>
                        </li>
                        <li className="page-item active">
                          <Link className="page-link" href=".">
                            1
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link className="page-link" href=".">
                            2
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link className="page-link" href=".">
                            3
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link className="page-link" href=".">
                            4
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link className="page-link" href=".">
                            5
                          </Link>
                        </li>
                        <li className="page-item next">
                          <Link className="page-link" href=".">
                            »
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CourseDetails;
