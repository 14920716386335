import React from "react";
import { Link } from 'react-router-dom'
import "../systemadmin.css";

import logo from "../assets/img/logo.png";
import profile from "../assets/profile/user-1.jpg";
import course1 from "../assets/img/course1.jpg";
import course2 from "../assets/img/course2.jpg";
import course3 from "../assets/img/test1.jpg";
import course4 from "../assets/img/test2.jpg";
const SystemAdmin = () => {
  /* ==== Start: get release the course contents requests from 
    === Steps to get that requests===
    1.import requests query from requestsApiSlice 
    
    const {data:requests , isLoading , error} = useGetRequestsQuery();

    2.Then map over that requests and display them all the information in the Requests below

   ==== End : request for release course contents end */
  return (
    <div className="system-admin-dashboard bg-white">
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        {/* Sidebar Start */}
        <aside className="left-sidebar">
          {/* Sidebar scroll*/}
          <div>
            <div className="brand-logo d-flex align-items-center justify-content-between">
              <Link to='/system-admin' className="text-nowrap logo-img">
                <img src={logo} width={180} alt="Logoooo" />
              </Link>
              <div
                className="close-btn d-xl-none d-block sidebartoggler cursor-pointer"
                id="sidebarCollapse"
              >
                <i className="ti ti-x fs-8" />
              </div>
            </div>
            {/* Sidebar navigation*/}
            <nav className="sidebar-nav scroll-sidebar" data-simplebar>
              <ul id="sidebarnav">
                <li className="sidebar-item">
                  <Link
                    to="/admin"
                    className="sidebar-link"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="ti ti-layout-dashboard" />
                    </span>
                    <span className="hide-menu">Home</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link
                    to="/manage-user"
                    className="sidebar-link"
                    href="./ui-buttons.html"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="ti ti-article" />
                    </span>
                    <span className="hide-menu">Manage User</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link
                    to="/manage-course"
                    className="sidebar-link"
                    href="./ui-alerts.html"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="ti ti-alert-circle" />
                    </span>
                    <span className="hide-menu">Manage Course</span>
                  </Link>
                </li>
              </ul>
            </nav>
            {/* End Sidebar navigation */}
          </div>
          {/* End Sidebar scroll*/}
        </aside>
        {/*  Sidebar End */}
        {/*  Main wrapper */}
        <div className="body-wrapper">
          {/*  Header Start */}
          <header className="app-header">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div
                className="navbar-collapse justify-content-end px-0"
                id="navbarNav"
              >
                <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link nav-icon-hover"
                      href="."
                      id="drop2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={profile}
                        alt=""
                        width={35}
                        height={35}
                        className="rounded-circle"
                      />
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up"
                      aria-labelledby="drop2"
                    >
                      <div className="message-body">
                        <a
                          href="."
                          className="d-flex align-items-center gap-2 dropdown-item"
                        >
                          <i className="ti ti-user fs-6" />
                          <p className="mb-0 fs-3">My Profile</p>
                        </a>
                        <a
                          href="./authentication-login.html"
                          className="btn btn-outline-primary mx-3 mt-2 d-block"
                        >
                          Logout
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </header>
          {/*  Header End */}
          <div className="container-fluid">
            {/*  Row 1 */}
            <div className="row">
              <div className="col-lg-8 d-flex align-items-strech">
                <div className="card w-100">
                  <div className="card-body">
                    <div className="d-sm-flex d-block align-items-center justify-content-between mb-9">
                      <div className="mb-3 mb-sm-0">
                        <h5 className="card-title fw-semibold">
                          U-learn Status using Chart
                        </h5>
                      </div>
                      <div>
                        <select className="form-select">
                          <option value={1}>March 2023</option>
                          <option value={2}>April 2023</option>
                          <option value={3}>May 2023</option>
                          <option value={4}>June 2023</option>
                        </select>
                      </div>
                    </div>
                    <div id="chart" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-12">
                    {/* Yearly Breakup */}
                    <div className="card overflow-hidden">
                      <div className="card-body p-4">
                        <h5 className="card-title mb-9 fw-semibold">
                          Total Courses
                        </h5>
                        <div className="row align-items-center">
                          <div className="col-8">
                            <h4 className="fw-semibold mb-3">100</h4>
                          </div>
                          <div className="col-4">
                            <div className="d-flex justify-content-center">
                              <div id="breakup" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    {/* Monthly Earnings */}
                    <div className="card">
                      <div className="card-body">
                        <div className="row alig n-items-start">
                          <div className="col-8">
                            <h5 className="card-title mb-9 fw-semibold">
                              Users
                            </h5>
                            <h4 className="fw-semibold mb-3">20</h4>
                          </div>
                        </div>
                      </div>
                      <div id="earning" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 d-flex align-items-stretch">
                <div className="card w-100">
                  <div className="card-body p-4">
                    <h5 className="card-title fw-semibold mb-4">
                      Release Course Request
                    </h5>
                    <div className="table-responsive">
                      <table className="table text-nowrap mb-0 align-middle">
                        <thead className="text-dark fs-4">
                          <tr>
                            <th className="border-bottom-0">
                              <h6 className="fw-semibold mb-0">Id</h6>
                            </th>
                            <th className="border-bottom-0">
                              <h6 className="fw-semibold mb-0">
                                {" "}
                                purchased Course{" "}
                              </h6>
                            </th>
                            <th className="border-bottom-0">
                              <h6 className="fw-semibold mb-0">Payed at</h6>
                            </th>
                            <th className="border-bottom-0">
                              <h6 className="fw-semibold mb-0">
                                Transaction Detail
                              </h6>
                            </th>
                            <th className="border-bottom-0">
                              <h6 className="fw-semibold mb-0">Approve</h6>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border-bottom-0">
                              <h6 className="fw-semibold mb-0">1</h6>
                            </td>
                            <td className="border-bottom-0">
                              <h6 className="fw-semibold mb-1">
                                Web Development
                              </h6>
                              <span className="fw-normal">
                                By Barnabas Tekalign
                              </span>
                            </td>
                            <td className="border-bottom-0">
                              <p className="mb-0 fw-normal">
                                Aug 10 , 13:35 ,2023
                              </p>
                            </td>
                            <td className="border-bottom-0">
                              <div className="d-flex align-items-center gap-2">
                                <button className="detail-btn">Details</button>
                              </div>
                            </td>
                            <td className="border-bottom-0">
                              <div className="d-flex align-items-center gap-2">
                                <button className="approve-btn">Approve</button>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="border-bottom-0">
                              <h6 className="fw-semibold mb-0">2</h6>
                            </td>
                            <td className="border-bottom-0">
                              <h6 className="fw-semibold mb-1">
                                Web Development
                              </h6>
                              <span className="fw-normal">
                                By Barnabas Tekalign
                              </span>
                            </td>
                            <td className="border-bottom-0">
                              <p className="mb-0 fw-normal">
                                Aug 10 , 13:35 ,2023
                              </p>
                            </td>
                            <td className="border-bottom-0">
                              <div className="d-flex align-items-center gap-2">
                                <button className="detail-btn">Details</button>
                              </div>
                            </td>
                            <td className="border-bottom-0">
                              <div className="d-flex align-items-center gap-2">
                                <button className="approve-btn">
                                  Approved
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <h5 className="fw-semibold mb-4 ms-4">
                Most Rated Course
              </h5>
              <div className="col-sm-6 col-xl-3">
                <div className="card overflow-hidden rounded-2">
                  <div className="position-relative">
                    <a href=".">
                      <img
                        src={course2}
                        className="card-img-top rounded-0"
                        alt="..."
                      />
                    </a>
                  </div>
                  <div className="card-body d-flex flex-column justify-content-center align-items-center pt-3 p-4">
                    <h6 className="fw-semibold">Web Development</h6>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="fw-light">
                        By Barnabas Tekalign
                      </h6>
                      <ul className="list-unstyled d-flex align-items-center mb-0">
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="card overflow-hidden rounded-2">
                  <div className="position-relative">
                    <a href=".">
                      <img
                        src={course3}
                        className="card-img-top rounded-0"
                        alt="..."
                      />
                    </a>
                  </div>
                  <div className="card-body d-flex flex-column justify-content-center align-items-center pt-3 p-4">
                    <h6 className="fw-semibold">Web Development</h6>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="fw-light">
                        By Barnabas Tekalign
                      </h6>
                      <ul className="list-unstyled d-flex align-items-center mb-0">
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="card overflow-hidden rounded-2">
                  <div className="position-relative">
                    <a href=".">
                      <img
                        src={course4}
                        className="card-img-top rounded-0"
                        alt="..."
                      />
                    </a>
                  </div>
                  <div className="card-body d-flex flex-column justify-content-center align-items-center pt-3 p-4">
                    <h6 className="fw-semibold">Web Designing</h6>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="fw-light">
                        By Nasiha
                      </h6>
                      <ul className="list-unstyled d-flex align-items-center mb-0">
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="card overflow-hidden rounded-2">
                  <div className="position-relative">
                    <a href=".">
                      <img
                        src={course1}
                        className="card-img-top rounded-0"
                        alt="..."
                      />
                    </a>
                  </div>
                  <div className="card-body d-flex flex-column justify-content-center align-items-center pt-3 p-4">
                    <h6 className="fw-semibold">MERN Stack</h6>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="fw-light">
                        By Amina
                      </h6>
                      <ul className="list-unstyled d-flex align-items-center mb-0">
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemAdmin;
