import React from "react";
// import "../../counter";

const MiddlePart = () => {
  return (
    <section className="counter-section">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="counter">
              <span className="count" data-count="1000">
                1000
              </span>
              <p>Students</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="counter">
              <span className="count" data-count="50">
                50
              </span>
              <p>Instructors</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="counter">
              <span className="count" data-count="20">
                100
              </span>
              <p>Courses</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MiddlePart;
