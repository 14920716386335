import React from "react";
import Button from "react-bootstrap/Button";
import styles from "./CheckOut.module.css";

function SummaryCheckOut() {
  return (
    <div className="p-5 mt-4">
      <h1 className="d-flex justify-content-between mb-5"> Summary</h1>

      <div className="d-flex justify-content-between my-0 pb-0">
        <p className="opacity-50 "> Original Price</p>
        <p className="opacity-50"> 59.99 Birr</p>
      </div>
      <hr className="my-0" />

      <div className="d-flex justify-content-between my-0 pt-2">
        <p className="fw-bold"> Total:</p>
        <p className="fw-bold"> 59.99 Birr</p>
      </div>
      <Button
        variant="warning"
        size="lg"
        className={` mt-5 py-3 fw-bold ${styles.button}`}
      >
        Complete checkout
      </Button>
    </div>
  );
}

export default SummaryCheckOut;
