import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min'
import { createBrowserRouter ,createRoutesFromElements ,Route ,RouterProvider } from 'react-router-dom';
import App from './App';
import Home from './pages/Home';
import CourseDetails from './pages/CourseDetails';
import TeachOnUs from './pages/TeachOnUs';
import CartList from './pages/CartList'
import CheckOutPage from './pages/CheckOutPage'
import WishList from './pages/WishList'
import MyCourses from './pages/MyCourses'
import Learn from './pages/Learn'
import SystemAdmin from './pages/SystemAdmin'
import ManageUser from './pages/ManageUser'
import ManageCourse from './pages/ManageCourse'
import store from './store'
import { Provider } from 'react-redux'
import PrivateRoute from './components/home/PrivateRoute';
import AdminRoute from './components/home/AdminRoute';
import InstructorRoute from './components/home/InstructorRoute';
import CourseManagement from './components/CourseManagment'
import CourseAnalytics from './components/CourseAnalytics'
import DiscussionForum from './components/DiscussionForum'
import Instructor from './pages/Instructor';


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element ={<App/>}>
      <Route index ={true} path='/' element={<Home/>}></Route>
      <Route path='/course/:id' element={<CourseDetails/>}></Route>
      <Route path='/TeachOnUs' element={<TeachOnUs/>}></Route>
      <Route path='/CartList' element={<CartList/>}></Route>
      <Route path='/WishList' element={<WishList/>}></Route>
      <Route path='/my-course' element ={<MyCourses/>}></Route>
      <Route path='/learn' element ={<Learn/>}></Route>

      {/*=== The route for system admin route is commented below ===  */}

      {/* <Route path='/:id' element ={<SystemAdmin/>}></Route>
      <Route path='/:id' element ={<ManageUser/>}></Route>
      <Route path='/:id' element ={<ManageCourse/>}></Route>
     */}

      {/* ===== The page or route that can be seen by registered user only ==== */}

      <Route path='' element ={<PrivateRoute/>}>
      <Route path='/check-out' element={<CheckOutPage/>}></Route>
      </Route>

      <Route path='' element ={<AdminRoute/>}>
      <Route path='/admin' element ={<SystemAdmin/>}></Route>
      <Route path='/manage-user' element ={<ManageUser/>}></Route>
      <Route path='/manage-course' element ={<ManageCourse/>}></Route>
      </Route> 
      
      <Route path='' element ={<InstructorRoute/>}>
      <Route path='/instructor' element ={<Instructor/>}></Route>
      <Route path='/course-management' element ={<CourseManagement/>}></Route>
      <Route path='/course-analytics' element ={<CourseAnalytics/>}></Route>
      <Route path='/discussion-forum' element ={<DiscussionForum/>}></Route>
      </Route> 

    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
<Provider store={store}>
<RouterProvider router={router}/>
</Provider>
  </React.StrictMode>
);

