import React from "react";
import test1 from "../../assets/img/course1.jpg";
import test2 from "../../assets/img/test1.jpg";
import test3 from "../../assets/img/test2.jpg";

const Testimonials = () => {
  return (
    <div className="testimonials">
      <div className="container">
        <div className="section-title">
          <h2>Hear From Our Students Here</h2>
        </div>
        <div id="testimonialsCarousel" className="carousel slide">
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#testimonialsCarousel"
              data-bs-slide-to={0}
              className="active indicator-dot"
              aria-current="true"
              aria-label="Slide 1"
            />
            <button
              type="button"
              data-bs-target="#testimonialsCarousel"
              data-bs-slide-to={1}
              className="indicator-dot"
              aria-label="Slide 2"
            />
            <button
              type="button"
              data-bs-target="#testimonialsCarousel"
              data-bs-slide-to={2}
              className="indicator-dot"
              aria-label="Slide 3"
            />
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row">
                <div className="col-lg-4 d-none d-lg-block position-relative">
                  <div className="text-over-image position-absolute top-50 start-50 translate-middle text-center overlay">
                    <p className="fw-bold text-white">
                      <i class="fa-solid fa-quote-left"></i>
                      This e-learning platform has revolutionized my learning
                      experience.
                      <i class="fa-solid fa-quote-right"></i>
                    </p>
                  </div>
                  <img src={test3} alt="..." className="img-fluid" />
                </div>
                <div className="col-lg-4 d-none d-lg-block position-relative">
                  <div className="text-over-image position-absolute top-50 start-50 translate-middle text-center overlay">
                    <p className="fw-bold text-white">
                      <i class="fa-solid fa-quote-left"></i>
                      This e-learning platform has revolutionized my learning
                      experience. <i class="fa-solid fa-quote-right"></i>
                    </p>
                  </div>
                  <img src={test2} alt="..." className="img-fluid" />
                </div>
                <div className="col-lg-4 position-relative">
                  <div className="text-over-image position-absolute top-50 start-50 translate-middle text-center overlay">
                    <p className="fw-bold text-white">
                      <i class="fa-solid fa-quote-left"></i>
                      This e-learning platform has revolutionized my learning
                      experience. <i class="fa-solid fa-quote-right"></i>
                    </p>
                  </div>
                  <img src={test1} alt="..." className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-lg-4 d-none d-lg-block position-relative">
                  <div className="text-over-image position-absolute top-50 start-50 translate-middle text-center overlay">
                    <p className="fw-bold text-white">
                      <i class="fa-solid fa-quote-left"></i>
                      This e-learning platform has revolutionized my learning
                      experience. <i class="fa-solid fa-quote-right"></i>
                    </p>
                  </div>
                  <img src={test2} alt="..." className="img-fluid" />
                </div>
                <div className="col-lg-4 d-none d-lg-block position-relative">
                  <div className="text-over-image position-absolute top-50 start-50 translate-middle text-center overlay">
                    <p className="fw-bold text-white">
                      <i class="fa-solid fa-quote-left"></i>
                      This e-learning platform has revolutionized my learning
                      experience. <i class="fa-solid fa-quote-right"></i>
                    </p>
                  </div>
                  <img src={test1} alt="..." className="img-fluid" />
                </div>
                <div className="col-lg-4 position-relative">
                  <div className="text-over-image position-absolute top-50 start-50 translate-middle text-center overlay">
                    <p className="fw-bold text-white">
                      <i class="fa-solid fa-quote-left"></i>
                      This e-learning platform has revolutionized my learning
                      experience. <i class="fa-solid fa-quote-right"></i>
                    </p>
                  </div>
                  <img src={test3} alt="..." className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-lg-4 d-none d-lg-block position-relative">
                  <div className="text-over-image position-absolute top-50 start-50 translate-middle text-center overlay">
                    <p className="fw-bold text-white">
                      <i class="fa-solid fa-quote-left"></i>
                      This e-learning platform has revolutionized my learning
                      experience. <i class="fa-solid fa-quote-right"></i>
                    </p>
                  </div>
                  <img src={test3} alt="..." className="img-fluid" />
                </div>
                <div className="col-lg-4 d-none d-lg-block position-relative">
                  <div className="text-over-image position-absolute top-50 start-50 translate-middle text-center overlay">
                    <p className="fw-bold text-white">
                      <i class="fa-solid fa-quote-left"></i>
                      This e-learning platform has revolutionized my learning
                      experience. <i class="fa-solid fa-quote-right"></i>
                    </p>
                  </div>
                  <img src={test1} alt="..." className="img-fluid" />
                </div>
                <div className="col-lg-4 position-relative">
                  <div className="text-over-image position-absolute top-50 start-50 translate-middle text-center overlay">
                    <p className="fw-bold text-white">
                      <i class="fa-solid fa-quote-left"></i>
                      This e-learning platform has revolutionized my learning
                      experience. <i class="fa-solid fa-quote-right"></i>
                    </p>
                  </div>
                  <img src={test2} alt="..." className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#testimonialsCarousel"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#testimonialsCarousel"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
