import React from "react";
// import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

const AdminRoute = () => {
  // const { userInfo } = useSelector((state) => state.auth);
  
  const admin= true;

  return admin ? <Outlet /> : <Navigate to="/" replace />;
};

export default AdminRoute;
