import React from "react";
import Button from "react-bootstrap/Button";
import styles from "./CartListCourse.module.css";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import courseImg from '../../assets/img/course1.jpg'
import Star from "../Star";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCart } from "../../slice/cartSlice";

const CartListCourse = () => {
  const dispatch = useDispatch();
  const { coursesInCart } = useSelector((state) => state.cart);

  const removeCourseInCart = (id) => {
    dispatch(removeFromCart(id));
  };

  return (
    <>
      {coursesInCart.length === 0 ? (
        <h2 className="fw-lighter">No courses in cart</h2>
      ) : (
        <div className="mb-3">{coursesInCart.length} course item in cart.</div>
      )}
      {coursesInCart.map((course) => (
        <div
          key={course.id}
          className="d-flex border-top py-4 justify-content-between"
        >
          <div className="container-fluid d-flex justify-content-between ">
            <div className="d-flex mr-4">
              {/* <img src={course.courseCover} className={styles.Img} alt="." /> */}
              <img src={courseImg} className={styles.Img} alt="." />
              <div className="">
                <h6 className={`fs-5  my-1 ${styles.txt2}`}>{course.title}</h6>
                <p class={`opacity-50 my-1 ${styles.txt3} `}>
                  {course.instructor.name}
                </p>

                <ul className="list-group list-group-horizontal my-0 h-0">
                  <li
                    className={`list-group-item border-0 bg-success-subtle fw-bold p-1 ${styles.txt}`}
                  >
                    updated Recently
                  </li>
                  <li className="list-group-item border-0 p-0 ">
                    <Star stars={4.7} reviews={215778} />
                  </li>
                </ul>

                <div className="d-inline-block">
                  <ul className="list-group list-group-horizontal my-0">
                    <li
                      className={`list-group-item disabled border-0 text-nowrap ${styles.txt3}`}
                    >
                      13 total hours
                    </li>
                    <li
                      className={`list-group-item disabled border-0 text-nowrap ${styles.txt3}`}
                    >
                      . 92 lectures
                    </li>
                    <li
                      className={`list-group-item disabled border-0 text-nowrap ${styles.txt3}`}
                    >
                      .All Levels
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <ButtonGroup vertical>
              <Button
                onClick={() => removeCourseInCart(course.id)}
                className="text-end text-warning  p-0 text-nowrap"
                variant="white"
                size="sm"
              >
                Remove
              </Button>
              <Button
                onClick={""}
                className="text-end text-warning  p-0 text-nowrap"
                variant="white"
                size="sm"
              >
                save for letter
              </Button>
              <Button
                onClick={""}
                className="text-end text-warning  p-0 text-nowrap"
                variant="white"
                size="sm"
              >
                move to wishlist
              </Button>
            </ButtonGroup>
          </div>

          <div className="p-3">
            <p className="text-warning fs-6 fw-bolder p-0  m-0 text-nowrap">
              15.99 Birr
            </p>
            <p className="opacity-50 text-decoration-line-through p-0 m-0">
              74.99 Birr
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default CartListCourse;
