import React from "react";
import { Link } from "react-router-dom";
import instImg from "../assets/img/course1.jpg";
import coursePreview from "../assets/videos/AdobeStock_194449450_Video_HD_Preview.mp4";
import Footer from "../components/home/Footer";
import Navbar from "../components/home/Navbar";
const Learn = () => {
  const userHasPurchased = true;
  return (
    <>
      <Navbar />
      <div className="course-details">
        <div className="container all-course-details-container">
          <h5 className="course-title fw-bolder">Adobe Xd</h5>
          <div className="instructor-details row">
            <div className="col-lg-4">
              <img src={instImg} alt="no" />{" "}
              <span className="fw-bold">
                A course by Prof.Barnabas Tekalign
              </span>
            </div>
          </div>
          <div className="all-about-course row justify-content-center align-items-center">
            <div className="course-preview row">
              <video controls className="col-lg-6 mt-3">
                <source src={coursePreview} />
              </video>
            </div>
            <div className="course-req-content row justify-content-center align-items-center">
              <div className="col-lg-12 course-requirement">
                <div className="sec-title">
                  <h5 className="fw-bolder">Course Requirement</h5>
                </div>
                <ul className="req-list">
                  <li className="req-item">
                    Access to computer and internet connection
                  </li>
                  <li className="req-item">
                    Access to computer and internet connection
                  </li>
                  <li className="req-item">
                    Access to computer and internet connection
                  </li>
                </ul>
              </div>
            </div>
            {userHasPurchased ? (
              <div className="resources row p-3">
                <div className="col-lg-4">
                  <h5 className="fw-bolder">Resources</h5>
                  <ul className="list-group ">
                    <li className="list-group-item ">
                      <a href="path/to/your-ppt-file.ppt" download>
                        <i className="fas fa-file-powerpoint ppt-icon mr-2" />{" "}
                        PowerPoint Presentation
                      </a>
                    </li>
                    <li className="list-group-item ">
                      <a href="path/to/your-pdf-file.pdf" download>
                        <i className="fas fa-file-pdf pdf-icon mr-2" /> PDF
                        Document
                      </a>
                    </li>
                    <li className="list-group-item">
                      <a href="path/to/your-quiz-file.zip" download>
                        <i className="fas fa-file-archive zip-icon mr-2" /> Quiz
                        Resources (ZIP)
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="your-progress col-lg-8 p-5">
                  <div className="col-lg-4 mt-2">
                    <p>percent completed : 80%</p>
                  </div>
                  <div className="col-lg-4">
                    <p>
                      <a href="path/to/certificate.pdf" download>
                        <button className="btn certificate-btn">
                          Take Certificate
                        </button>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="course-contents p- w-100">
              <div className="container">
                <h5 className="mb-3 ms-2 fw-bolder">Course Contents</h5>
                <div id="courseAccordion">
                  <div className="all-unit-container">
                    {/* Lesson 1 */}
                    <div className="card ">
                      <div className="card-header" id="lesson-1-heading">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link text-decoration-none"
                            data-toggle="collapse"
                            data-target="#lesson-1-content"
                            aria-expanded="false"
                            aria-controls="lesson-1-content"
                          >
                            {`Lesson 1: Introduction`}
                            {!userHasPurchased && (
                              <span className="locked-icon"> 🔒</span>
                            )}
                          </button>
                        </h5>
                      </div>

                      <div
                        id="lesson-1-content"
                        className="collapse"
                        aria-labelledby="lesson-1-heading"
                        data-parent="#courseAccordion"
                      >
                        <div className="card-body">
                          {userHasPurchased ? (
                            <video controls className="w-100">
                              <source src={coursePreview} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <div className="locked-message">
                              This lesson is locked. Please purchase the course
                              to unlock.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Lesson 2 */}
                    <div className="card ">
                      <div className="card-header" id="lesson-2-heading">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link text-decoration-none"
                            data-toggle="collapse"
                            data-target="#lesson-2-content"
                            aria-expanded="false"
                            aria-controls="lesson-2-content"
                          >
                            {`Lesson 2: Installing Adobe XD`}
                            {!userHasPurchased && (
                              <span className="locked-icon"> 🔒</span>
                            )}
                          </button>
                        </h5>
                      </div>

                      <div
                        id="lesson-2-content"
                        className="collapse"
                        aria-labelledby="lesson-2-heading"
                        data-parent="#courseAccordion"
                      >
                        <div className="card-body">
                          {userHasPurchased ? (
                            <video controls className="w-100">
                              <source src={coursePreview} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <div className="locked-message">
                              This lesson is locked. Please purchase the course
                              to unlock.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <nav aria-label="Lesson pagination">
                      <ul className="pagination justify-content-center">
                        <li className="page-item disabled prev">
                          <Link className="page-link" href="." tabIndex={-1}>
                            «
                          </Link>
                        </li>
                        <li className="page-item active">
                          <Link className="page-link" href=".">
                            1
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link className="page-link" href=".">
                            2
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link className="page-link" href=".">
                            3
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link className="page-link" href=".">
                            4
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link className="page-link" href=".">
                            5
                          </Link>
                        </li>
                        <li className="page-item next">
                          <Link className="page-link" href=".">
                            »
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>

                  {/* Add more lessons here... */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Learn;
