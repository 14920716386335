import { COURSES_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const courseApiSlice = apiSlice.injectEndpoints({
    endpoints:(builder) => ({
        getCourses:builder.query({
            query:() =>({
                url:COURSES_URL,
            }),
            keepUnusedDataFor:5,
            providesTags:['Courses'],
        }),
        getCourseDetails:builder.query({
            query:(courseId) => ({
                url:`${COURSES_URL}/${courseId}`,
            }),
            keepUnusedDataFor:5,
        }),
        createCourse:builder.mutation({
           query:(data) =>({
            url:COURSES_URL,
            method:'POST',
            body:{...data}
           }) ,
           invalidatesTags:['Course'],
        }),
        updateCourse:builder.mutation({
            query:(data) =>({
             url:`${COURSES_URL}/${data.id}`,
             method:'PUT',
             body:data,
            }),
            invalidatesTags:['Courses'],
        })
    }),
});

export const {useGetCoursesQuery , useGetCourseDetailsQuery ,useCreateCourseMutation ,useUpdateCourseMutation} = courseApiSlice