import React from "react";
import Hero from "../components/home/Hero";
import Navbar from "../components/home/Navbar";
import Footer from "../components/home/Footer";
import MiddlePart from "../components/home/MiddlePart";
import WhyWin from "../components/home/WhyWin";
import TopCourse from "../components/home/TopCourse";
import Testimonials from "../components/home/Testimonials";
import { useGetCoursesQuery } from "../slice/coursesApiSlice";
import Loader from "../components/home/Loader";
// import { useSelector } from "react-redux";
//import WishList from './WishList'

const Home = () => {
  const { data: courses, isLoading, isError } = useGetCoursesQuery();

  if (isLoading) {
    return <Loader/>;
  }

  if (isError) {
    return <Loader/>
  }

  if (!courses || !courses) {
    return <Loader/>;
  }


  return (
   

    <div className="home-pag">
      <Navbar />
      <Hero />
      <MiddlePart />
      <TopCourse courses ={courses.data.courses} />
      <WhyWin />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default Home;
