import React from 'react';
import { Row, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import profile from "../assets/profile/user-1.jpg";

// import 'bootstrap-icons/font/bootstrap-icons.css';

const CourseAnalytics = () => {


    return (
        <div className="instructor-dashboard bg-white">
            <div
                className="page-wrapper"
                id="main-wrapper"
                data-layout="vertical"
                data-navbarbg="skin6"
                data-sidebartype="full"
                data-sidebar-position="fixed"
                data-header-position="fixed"
            >
                {/* Sidebar Start */}
                <aside className="left-sidebar">
                    {/* Sidebar scroll*/}
                    <div>
                        <div className="brand-logo d-flex align-items-center justify-content-between">
                            <Link to='/instructor' className="text-nowrap logo-img">
                                <img src={logo} width={180} alt="Logoooo" />
                            </Link>
                            <div
                                className="close-btn d-xl-none d-block sidebartoggler cursor-pointer"
                                id="sidebarCollapse"
                            >
                                <i className="ti ti-x fs-8" />
                            </div>
                        </div>
                        {/* Sidebar navigation*/}
                        <nav className="sidebar-nav scroll-sidebar" data-simplebar>
                            <ul id="sidebarnav">
                                <li className="sidebar-item">
                                    <Link to='/instructor'
                                        className="sidebar-link"
                                        aria-expanded="false"
                                    >
                                        <span>
                                            <i className="ti ti-layout-dashboard" />
                                        </span>
                                        <span className="hide-menu">Home</span>
                                    </Link>
                                </li>
                                <li className="sidebar-item">
                                    <Link to='/course-management'
                                        className="sidebar-link"
                                        href="./ui-buttons.html"
                                        aria-expanded="false"
                                    >
                                        <span>
                                            <i className="ti ti-article" />
                                        </span>
                                        <span className="hide-menu">Course Management</span>
                                    </Link>
                                </li>
                                <li className="sidebar-item">
                                    <Link to='/course-analytics'
                                        className="sidebar-link"
                                        href="./ui-alerts.html"
                                        aria-expanded="false"
                                    >
                                        <span>
                                            <i className="ti ti-alert-circle" />
                                        </span>
                                        <span className="hide-menu">Course Analytics</span>
                                    </Link>
                                </li>
                                <li className="sidebar-item">
                                    <Link to='/discussion-forum'
                                        className="sidebar-link"
                                        href="./ui-alerts.html"
                                        aria-expanded="false"
                                    >
                                        <span>
                                            <i className="ti ti-alert-circle" />
                                        </span>
                                        <span className="hide-menu">Discussion Forum</span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                        {/* End Sidebar navigation */}
                    </div>
                    {/* End Sidebar scroll*/}
                </aside>
                {/*  Main wrapper */}
                <div className="body-wrapper">
                    {/*  Header Start */}
                    <header className="app-header">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <div
                                className="navbar-collapse justify-content-end px-0"
                                id="navbarNav"
                            >
                                <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                                    <li className="nav-item dropdown">
                                        <a
                                            className="nav-link nav-icon-hover"
                                            href="."
                                            id="drop2"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <img
                                                src={profile}
                                                alt=""
                                                width={35}
                                                height={35}
                                                className="rounded-circle"
                                            />
                                        </a>
                                        <div
                                            className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up"
                                            aria-labelledby="drop2"
                                        >
                                            <div className="message-body">
                                                <a
                                                    href="."
                                                    className="d-flex align-items-center gap-2 dropdown-item"
                                                >
                                                    <i className="ti ti-user fs-6" />
                                                    <p className="mb-0 fs-3">My Profile</p>
                                                </a>
                                                <a
                                                    href="./authentication-login.html"
                                                    className="btn btn-outline-primary mx-3 mt-2 d-block"
                                                >
                                                    Logout
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </header>
                    {/*  Header End */}
                    <div className="container-fluid">
                        {/* Row 1 */}
                        <div className="row">
                            <div className="col-lg-11 d-flex align-items-stretch">
                                <div className="card w-100">
                                    <div className="card-body">
                                        <div className="mb-3 mb-sm-0" >
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>


                                            </div>
                                        </div>
                                        <br />

                                        <Container>
                                            <Row className="mb-2">
                                                <div >

                                                    <Card className='w-45'>

                                                        <Card.Body >

                                                            <Card.Title className='fs-7 '><i className='bi  bi-123 fs-10 text-warning px-4 py-4'></i> Number of Students Enrolled</Card.Title>
                                                            <Card.Text>
                                                                234 students enrolled
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                    <Card className='w-45'>

                                                        <Card.Body className='inline'>

                                                            <Card.Title className='fs-7 '><i className='bi  bi-calendar-week-fill fs-10 text-warning px-4 py-4'></i> Time spent on the course</Card.Title>
                                                            <Card.Text>22 hour spent on this course</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                    <Card className='w-45'>

                                                        <Card.Body className='inline'>

                                                            <Card.Title className='fs-7 '><i className='bi  bi-graph-up-arrow fs-10 text-warning px-4 py-4'></i> Time spent on the course</Card.Title>
                                                            <Card.Text>22 hour spent on this course</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Row>
                                        </Container>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                    
                </div>

            </div>


        </div>




    );
};




export default CourseAnalytics;