import React from "react";
import CheckOut from "../components/cart/CheckOut";
import SummaryCheckOut from "../components/cart/SummaryCheckOut";
import { Row, Col } from "react-bootstrap";

import styles from "../components/cart/CheckOut.module.css";
import Navbar from "../components/home/Navbar";
import Footer from "../components/home/Footer";

function CheckOutPage() {
  return (
    <div className=" bg-white ">
      <Navbar />
      <Row>
        <Col md={7}>
          {" "}
          <CheckOut />{" "}
        </Col>
        <Col className={`py-5 pr-5  ${styles.darkbackground} `}>
          <div className={` ${styles.fixedcol}`}>
            <SummaryCheckOut />
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default CheckOutPage;
