import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import profile from "../assets/profile/user-1.jpg";
import logo from "../assets/img/logo.png";
import { Form, FormGroup, Modal, Row, Col, Button } from "react-bootstrap";
const ManageUser = () => {
  const [EditModal, setEditModal] = useState(false);

  const showEditModal = () => setEditModal(true);
  const hideEditModal = () => setEditModal(false);

  const [selectedFile, setSelectedFile] = useState(null);
  console.log(selectedFile)

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <div className="manage-user">
      <div className="system-admin-dashboard bg-white">
        <div
          className="page-wrapper"
          id="main-wrapper"
          data-layout="vertical"
          data-navbarbg="skin6"
          data-sidebartype="full"
          data-sidebar-position="fixed"
          data-header-position="fixed"
        >
          {/* Sidebar Start */}
          <aside className="left-sidebar">
            {/* Sidebar scroll*/}
            <div>
              <div className="brand-logo d-flex align-items-center justify-content-between">
                <Link to="/system-admin" className="text-nowrap logo-img">
                  <img src={logo} width={180} alt="Logoooo" />
                </Link>
                <div
                  className="close-btn d-xl-none d-block sidebartoggler cursor-pointer"
                  id="sidebarCollapse"
                >
                  <i className="ti ti-x fs-8" />
                </div>
              </div>
              {/* Sidebar navigation*/}
              <nav className="sidebar-nav scroll-sidebar" data-simplebar>
                <ul id="sidebarnav">
                  <li className="sidebar-item">
                    <Link
                      to="/admin"
                      className="sidebar-link"
                      href="./index.html"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="ti ti-layout-dashboard" />
                      </span>
                      <span className="hide-menu">Home</span>
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link
                      to="/manage-user"
                      className="sidebar-link"
                      href="./ui-buttons.html"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="ti ti-article" />
                      </span>
                      <span className="hide-menu">Manage User</span>
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link
                      to="/manage-course"
                      className="sidebar-link"
                      href="./ui-alerts.html"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="ti ti-alert-circle" />
                      </span>
                      <span className="hide-menu">Manage Course</span>
                    </Link>
                  </li>
                </ul>
              </nav>
              {/* End Sidebar navigation */}
            </div>
            {/* End Sidebar scroll*/}
          </aside>
          {/*  Sidebar End */}
          {/*  Main wrapper */}
          <div className="body-wrapper">
            {/*  Header Start */}
            <header className="app-header">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div
                  className="navbar-collapse justify-content-end px-0"
                  id="navbarNav"
                >
                  <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link nav-icon-hover"
                        href="."
                        id="drop2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={profile}
                          alt=""
                          width={35}
                          height={35}
                          className="rounded-circle"
                        />
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up"
                        aria-labelledby="drop2"
                      >
                        <div className="message-body">
                          <a
                            href="."
                            className="d-flex align-items-center gap-2 dropdown-item"
                          >
                            <i className="ti ti-user fs-6" />
                            <p className="mb-0 fs-3">My Profile</p>
                          </a>
                          <a
                            href="."
                            className="d-flex align-items-center gap-2 dropdown-item"
                          >
                            <i className="ti ti-mail fs-6" />
                            <p className="mb-0 fs-3">My Account</p>
                          </a>
                          <a
                            href="."
                            className="d-flex align-items-center gap-2 dropdown-item"
                          >
                            <i className="ti ti-list-check fs-6" />
                            <p className="mb-0 fs-3">My Task</p>
                          </a>
                          <a
                            href="./authentication-login.html"
                            className="btn btn-outline-primary mx-3 mt-2 d-block"
                          >
                            Logout
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </header>
            {/*  Header End */}

            <div className="container-fluid">
              {/*  Row 1 */}
              <div className="row">
                <div className="col-lg-12 d-flex align-items-stretch">
                  <div className="card w-100">
                    <div className="card-body p-4">
                      <h5 className="card-title fw-semibold mb-4">Students</h5>
                      <div className="table-responsive">
                        <table className="table text-nowrap mb-0 align-middle">
                          <thead className="text-dark fs-4">
                            <tr>
                              <th className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">Id</h6>
                              </th>
                              <th className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">
                                  Course enrolled
                                </h6>
                              </th>
                              <th className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">Name</h6>
                              </th>
                              <th className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">Action</h6>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">1</h6>
                              </td>
                              <td className="border-bottom-0">
                                <span className="fw-normal">Web Designing</span>
                              </td>
                              <td className="border-bottom-0">
                                <p className="mb-0 fw-normal">Abraham</p>
                              </td>
                              <td className="border-bottom-0">
                                <div className="d-flex align-items-center gap-2">
                                  <button
                                    className="edit-btn"
                                    onClick={showEditModal}
                                  >
                                    Edit
                                  </button>
                                  <button className="delete-btn">Delete</button>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">2</h6>
                              </td>
                              <td className="border-bottom-0">
                                <span className="fw-normal">
                                  Web Development
                                </span>
                              </td>
                              <td className="border-bottom-0">
                                <p className="mb-0 fw-normal">Bonsa</p>
                              </td>
                              <td className="border-bottom-0">
                                <div className="d-flex align-items-center gap-2">
                                  <button
                                    className="edit-btn"
                                    onClick={showEditModal}
                                  >
                                    Edit
                                  </button>
                                  <button className="delete-btn">Delete</button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 d-flex align-items-stretch">
                  <div className="card w-100">
                    <div className="card-body p-4">
                      <h5 className="card-title fw-semibold mb-4">
                        Instructors
                      </h5>
                      <div className="table-responsive">
                        <table className="table text-nowrap mb-0 align-middle">
                          <thead className="text-dark fs-4">
                            <tr>
                              <th className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">Id</h6>
                              </th>
                              <th className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">
                                  Course enrolled
                                </h6>
                              </th>
                              <th className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">Name</h6>
                              </th>
                              <th className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">Action</h6>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">1</h6>
                              </td>
                              <td className="border-bottom-0">
                                <span className="fw-normal">Web Designing</span>
                              </td>
                              <td className="border-bottom-0">
                                <p className="mb-0 fw-normal">Abraham</p>
                              </td>
                              <td className="border-bottom-0">
                                <div className="d-flex align-items-center gap-2">
                                  <button
                                    className="edit-btn "
                                    onClick={showEditModal}
                                  >
                                    Edit
                                  </button>
                                  <button className="delete-btn">Delete</button>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="border-bottom-0">
                                <h6 className="fw-semibold mb-0">2</h6>
                              </td>
                              <td className="border-bottom-0">
                                <span className="fw-normal">
                                  Web Development
                                </span>
                              </td>
                              <td className="border-bottom-0">
                                <p className="mb-0 fw-normal">Bonsa</p>
                              </td>
                              <td className="border-bottom-0">
                                <div className="d-flex align-items-center gap-2">
                                  <button
                                    className="edit-btn"
                                    onClick={showEditModal}
                                  >
                                    Edit
                                  </button>
                                  <button className="delete-btn">Delete</button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal by amina */}
      <Modal
        show={EditModal}
        onHide={hideEditModal}
        centered
        className="custom-modal modal-lg"
        size="lg"
      >
        <Modal.Header closeButton className="modal-header py-0 px-3">
          <Modal.Title className="modal-title fw-bolder fs-9 p-0 m-0 text-center w-100">
            Edit course
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container">
            <Form>
              <FormGroup as={Row}>
                <Form.Label column sm="4">
                  Instructor name
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="e.g Abel "
                    maxLength={60}
                  />
                </Col>
              </FormGroup>

              <FormGroup as={Row}>
                <Form.Label column sm="4">
                  Student name
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text" placeholder="e.g Nasiha" />
                </Col>
              </FormGroup>

              <FormGroup as={Row}>
                <Form.Label column sm="4">
                  Course Id
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text" placeholder="1" />
                </Col>
              </FormGroup>

              <FormGroup as={Row}>
                <Form.Label column sm="4">
                  Course enrolled
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text" placeholder=" Web development " />
                </Col>
              </FormGroup>
              <div className="py-4">
                <Button onClick={handleFileChange} variant="warning">
                  update
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal> 
    </div>
  );
};

export default ManageUser;
