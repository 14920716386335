import React from "react";
import CartListCourse from "../components/cart/CartListCourse";
import CartPayment from "../components/cart/CartPayment";
import Navbar from "../components/home/Navbar";
import Footer from "../components/home/Footer";
import { useMediaQuery } from "react-responsive";

const CartList = () => {
  const small = useMediaQuery({ query: "(max-width: 500px)" });
  const large = useMediaQuery({ query: "(min-width:501px)" });

  return (
    <div className="bg-white">
      <Navbar />
      <div className="px-5">
        <h1 className="my-4">Shopping Cart</h1>

        {small && (
          <div class="d-flex flex-wrap-reverse justify-content-between">
            <div className="p-2 bg-white ">
              <CartListCourse />
            </div>
            <div className="p-2 bg-white">
              <CartPayment />
            </div>
          </div>
        )}

        {large && (
          <div class="d-flex justify-content-between">
            <div className="p-2 bg-white ">
              <CartListCourse />
            </div>
            <div className="p-2 bg-white">
              <CartPayment />
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default CartList;
