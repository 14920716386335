import React, { useState } from 'react';
import { Button, Col, Form,  Row, Modal, Container} from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import profile from "../assets/profile/user-1.jpg";



const CourseManagement = () => {

  const [file, setFile] = useState()

   console.log(file)
   
  const handleFile = (event) => {
    setFile(event.target.files[0])
    console.log(event.target.files[0])
  }
const [edit, setEdit] = useState(false)
  const showEdit = () => setEdit(true);
  const closeEdit = () => setEdit(false);




  const [show, setShow] = useState(false)

  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)



  return (
    <div className="instructor-dashboard bg-white">
    
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        {/* Sidebar Start */}
        <aside className="left-sidebar">
          {/* Sidebar scroll*/}
          <div>
        <div className="brand-logo d-flex align-items-center justify-content-between">
              <Link  to='/instructor' className="text-nowrap logo-img">
                <img src={logo} width={180} alt="Logoooo" />
              </Link>
              <div
                className="close-btn d-xl-none d-block sidebartoggler cursor-pointer"
                id="sidebarCollapse"
              >
                <i className="ti ti-x fs-8" />
              </div>
            </div>
            {/* Sidebar navigation*/}
            <nav className="sidebar-nav scroll-sidebar" data-simplebar>
              <ul id="sidebarnav">
                <li className="sidebar-item">
                  <Link to='/instructor'
                    className="sidebar-link"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="ti ti-layout-dashboard" />
                    </span>
                    <span className="hide-menu">Home</span>
                  </Link>
                </li>
              <li className="sidebar-item">
                <Link to='/course-management'
                  className="sidebar-link"
                  href="./ui-buttons.html"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-article" />
                  </span>
                  <span className="hide-menu">Course Management</span>
                </Link>
              </li>
              <li className="sidebar-item">
                <Link to='/course-analytics'
                  className="sidebar-link"
                  href="./ui-alerts.html"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-alert-circle" />
                  </span>
                  <span className="hide-menu">Course Analytics</span>
                </Link>
              </li>
              <li className="sidebar-item">
                <Link to='/discussion-forum'
                  className="sidebar-link"
                  href="./ui-alerts.html"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-alert-circle" />
                  </span>
                  <span className="hide-menu">Discussion Forum</span>
                </Link>
              </li>
            </ul>
          </nav>
          {/* End Sidebar navigation */}
        </div>
        {/* End Sidebar scroll*/}
      </aside>
        {/*  Main wrapper */}
        <div className="body-wrapper">
          {/*  Header Start */}
          <header className="app-header">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div
                className="navbar-collapse justify-content-end px-0"
                id="navbarNav"
              >
                <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link nav-icon-hover"
                      href="."
                      id="drop2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={profile}
                        alt=""
                        width={35}
                        height={35}
                        className="rounded-circle"
                      />
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up"
                      aria-labelledby="drop2"
                    >
                      <div className="message-body">
                        <a
                          href="."
                          className="d-flex align-items-center gap-2 dropdown-item"
                        >
                          <i className="ti ti-user fs-6" />
                          <p className="mb-0 fs-3">My Profile</p>
                        </a>
                        <a
                          href="./authentication-login.html"
                          className="btn btn-outline-primary mx-3 mt-2 d-block"
                        >
                          Logout
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </header>
          {/*  Header End */}
          <div className="container-fluid">
            {/* Row 1 */}
            <div className="row">
              <div className="col-lg-11 d-flex align-items-stretch">
                <div className="card w-100">
                  <div className="card-body">
                    <div className="mb-3 mb-sm-0" >
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h5 className="card-title fw-semibold">Course Management</h5>
                        <div>
                         
                        <Button variant='outline-warning' className='mx-3 inline' onClick={handleShow}>Add</Button>
                        <Button variant='outline-warning' onClick={showEdit}>Edit </Button>
                        </div>
                      </div>
                    </div>
                    <br />
                    <Form>
                      <Container>
                        <Row className="mb-2">
                          <Col sm="12">
                            <Form.Label className="text-left text-warning">Course Title</Form.Label>

                            <Form.Control type="text" placeholder="e.g. Learning Python from scratch" maxLength={60} />
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col sm="12">
                            <Form.Label className="text-left text-warning">Course Description</Form.Label>
                            <Form.Control as="textarea" placeholder="e.g." rows={3} maxLength={500} />
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col sm="12">
                            <Form.Label className="text-left text-warning">Course Content</Form.Label>
                            <Form.Control as="textarea" placeholder="e.g." rows={7} maxLength={1000} />
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col sm="12" >
                            <Form.Label className="text-left text-warning" >Upload Course</Form.Label>
                            <Form.Control className='mb-2' type="file" onChange={handleFile} />
                            <Button variant='outline-warning mb-2'>upload</Button>
                          </Col>
                        </Row>



                      </Container>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <Modal
        show={show}
        onHide={handleClose}>
        <Modal.Header closeButton>
          Add Course
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Container>
              <Row className="mb-2">
                <Col sm="12">
                  <Form.Label className="text-left text-warning">Course Title</Form.Label>

                  <Form.Control type="text" placeholder="e.g. Learning Python from scratch" maxLength={60} />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col sm="12">
                  <Form.Label className="text-left text-warning">Course Description</Form.Label>
                  <Form.Control as="textarea" placeholder="e.g." rows={3} maxLength={500} />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col sm="12">
                  <Form.Label className="text-left text-warning">Course Content</Form.Label>
                  <Form.Control as="textarea" placeholder="e.g." rows={7} maxLength={1000} />
                </Col>
              </Row>
              <Row>
                <Button variant="warning" type='submit' block>
                  ADD course title
                </Button>
              </Row>

            </Container>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>Close</Button>

        </Modal.Footer>
      </Modal>


      <Modal
        show={edit}
        onHide={closeEdit}>
        <Modal.Header closeButton>
          Edit
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Container>
              <Row className="mb-2">
                <Col sm="12">
                  <Form.Label className="text-left text-warning">Course Title</Form.Label>

                  <Form.Control type="text" placeholder="e.g. Learning Python from scratch" maxLength={60} />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col sm="12">
                  <Form.Label className="text-left text-warning">Course Description</Form.Label>
                  <Form.Control as="textarea" placeholder="e.g." rows={3} maxLength={500} />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col sm="12">
                  <Form.Label className="text-left text-warning">Course Content</Form.Label>
                  <Form.Control as="textarea" placeholder="e.g." rows={7} maxLength={1000} />
                </Col>
              </Row>
              <Row>
                <Button variant="warning" type='submit' block>
                  Edit Coure contents
                </Button>
              </Row>

            </Container>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeEdit}>Close</Button>

        </Modal.Footer>
      </Modal>

    </div>




  );
};




export default CourseManagement;