import React from "react";
import course1 from "../assets/img/course1.jpg";
import course2 from "../assets/img/course2.jpg";
import course3 from "../assets/img/test2.jpg";
import { Link } from "react-router-dom";
import Navbar from "../components/home/Navbar";
import Footer from "../components/home/Footer";

const MyCourses = () => {
  return (
    <>
      <Navbar />
      <div className="my-courses-page">
        <div className="container course-enrolled border-bottom">
          <h4 className="fw-bolder">My Courses</h4>
          <p>Overview of courses have enrolled in </p>
          <div className="list-of-course-enrolled">
            <div className="filter-in-page-using">
              <input type="search" placeholder=" Search for my course" />
            </div>
            <div className="all-enrolled-courses row mt-5">
              <div className="col-lg-3 col-sm-6 mb-4">
                <Link to="/learn">
                  <div className="card">
                    <img
                      src={course1}
                      className="card-img-top"
                      alt="Course 1"
                    />
                    <div className="card-body">
                      <div className="about-course">
                        <h4 className="course-title">Adobe Xd</h4>
                        <div className="course-progress d-flex justify-content-between">
                          <p>completed:70%</p>
                          <Link to="/learn" className="continue-link">
                            Continue
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-sm-6 mb-4">
                <Link to="/learn">
                  <div className="card">
                    <img
                      src={course2}
                      className="card-img-top"
                      alt="Course 1"
                    />
                    <div className="card-body">
                      <div className="about-course">
                        <h4 className="course-title">MERN Stack</h4>
                        <div className="course-progress d-flex justify-content-between">
                          <p>completed:70%</p>
                          <Link to="/learn" className="continue-link">
                            Continue
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-sm-6 mb-4">
                <Link to="/learn">
                  <div className="card">
                    <img
                      src={course3}
                      className="card-img-top"
                      alt="Course 1"
                    />
                    <div className="card-body">
                      <div className="about-course">
                        <h4 className="course-title text-nowrap">Figma</h4>
                        <div className="course-progress d-flex justify-content-between">
                          <p>completed:70%</p>
                          <Link to="/learn" className="continue-link">
                            Continue
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-sm-6 mb-4">
                <Link to="/learn">
                  <div className="card">
                    <img
                      src={course2}
                      className="card-img-top"
                      alt="Course 1"
                    />
                    <div className="card-body">
                      <div className="about-course">
                        <h4 className="course-title  text-nowrap">Figma</h4>
                        <div className="course-progress d-flex justify-content-between">
                          <p>completed:70%</p>
                          <Link to="/learn" className="continue-link">
                            Continue
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <nav aria-label="Course pagination">
                <ul className="pagination justify-content-center">
                  <li className="page-item disabled prev">
                    <a className="page-link" href="." tabIndex={-1}>
                      «
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href=".">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href=".">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href=".">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href=".">
                      4
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href=".">
                      5
                    </a>
                  </li>
                  <li className="page-item next">
                    <a className="page-link" href=".">
                      »
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyCourses;
