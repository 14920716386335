import React from "react";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";

// import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
import { Link } from "react-router-dom";
// import CheckOutPage from '../../pages/CheckOutPage';

const CartPayment = () => {
  const { coursesInCart } = useSelector((state) => state.cart);
  return (
    <>
      {coursesInCart.length === 0 ? ("") : (
        <div className="mt-3">
          <p className="opacity-50 my-0">Total:</p>
          <p className="fs-10 fw-bolder my-0">50.97 Birr</p>
          <p className="opacity-50 my-0 text-decoration-line-through">
            160.56 Birr
          </p>
          <p className="opacity-50  ">70% off</p>

          <Link to="/check-out">
            <Button variant="warning" className=" py-2 w-100 ">
              {" "}
              checkout
            </Button>
          </Link>
        </div>
      )}
    </>
  );
};

export default CartPayment;
