import React, { useState} from "react";
import { storage } from "../firebase";
import {ref ,uploadBytes , getDownloadURL} from "firebase/storage"
import { v4 } from "uuid";

import { Link } from "react-router-dom";
import "../systemadmin.css";
import profile from "../assets/profile/user-1.jpg";
import logo from "../assets/img/logo.png";
import course1 from "../assets/img/course1.jpg";
import course2 from "../assets/img/course2.jpg";
import course3 from "../assets/img/test1.jpg";
import course4 from "../assets/img/test2.jpg";
import {
  Form,
  Modal,
  Button,
  ModalHeader,
  ModalTitle,
  ModalBody,
} from "react-bootstrap";

const ManageCourse = () => {
  const [addCourseModal, setAddCourseModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const showUpdateModal = () => setUpdateModal(true);
  const closeUpdateModal = () => setUpdateModal(false);

  const showAddCourseModal = () => setAddCourseModal(true);
  const hideAddCourseModal = () => setAddCourseModal(false);
  
  // === Start:All the course attribute 
  const [title , setTitle] = useState('')
  const [instructor ,setInstructor] = useState('')
  const [description , setDescription] = useState('')
  const [summary , setSummary] = useState('')
  const [price , setPrice] = useState('')
  const [priceDiscount , setPriceDiscount] = useState('')
  const [category ,setCategory] = useState('')

  const [image ,setImage] = useState(null);
  const [video , setVideo ]=useState(null);
  const [zipFile , setZipFile] = useState(null)

  // === End: 

const handleAddCourse =(e) =>{
  e.preventDefault();
  console.log(title ,instructor ,description,summary,price ,priceDiscount ,category)
  /* === How the image or other file stored in firebase is accessed ===
  ## The way the firebase storage work is when the image 
  is uploaded the way that image or file is accessed in the project for 
  specific purpose is by ACCESSING A LINK.All the files stored in a firebase are
  actually stored in a public url and anyone can access if know the link
  */
  if(image == null) return;
  const imageRef = ref(storage ,`Files/${image.name + v4()}`);
  uploadBytes(imageRef, image)
  .then(() => {
    // Get the download URL of the uploaded image
    return getDownloadURL(imageRef);
  })
  .then((imageUrl) => {
    alert("Image Uploaded");
    // Now you have the 'imageUrl', which is the URL of the uploaded image.
    // You can send this URL to your server or use it as needed.
    console.log("Image URL:", imageUrl);

    // Continue with sending the 'imageUrl' to your server.
  })
  .catch((error) => {
    console.error("Error uploading image:", error);
  });
 
if(video == null) return;
const videoRef = ref(storage ,`Files/${video.name + v4()}`);
uploadBytes(videoRef, video)
.then(() => {
  // Get the download URL of the uploaded vide
  return getDownloadURL(videoRef);
})
.then((videoUrl) => {
  alert("video Uploaded");
  // Now you have the 'videoUrl', which is the URL of the uploaded video.
  // You can send this URL to your server or use it as needed.
  console.log("Video URL:", videoUrl);

  // Continue with sending the 'videoUrl' to your server.
})
.catch((error) => {
  console.error("Error uploading video:", error);
});

if(zipFile == null) return;
const zipFileRef = ref(storage ,`Files/${zipFile.name + v4()}`);
uploadBytes(zipFileRef, zipFile)
.then(() => {
  // Get the download URL of the uploaded file
  return getDownloadURL(zipFileRef);
})
.then((fileUrl) => {
  alert("File Uploaded");
  // Now you have the 'fileUrl', which is the URL of the uploaded file.
  // You can send this URL to your server or use it as needed.
  console.log("File URL:", fileUrl);

  // Continue with sending the 'videoUrl' to your server.
})
.catch((error) => {
  console.error("Error uploading file:", error);
});

}

  return (
    <div className="manage-course">
      <div className="system-admin-dashboard bg-white">
        <div
          className="page-wrapper"
          id="main-wrapper"
          data-layout="vertical"
          data-navbarbg="skin6"
          data-sidebartype="full"
          data-sidebar-position="fixed"
          data-header-position="fixed"
        >
          {/* Sidebar Start */}
          <aside className="left-sidebar">
            {/* Sidebar scroll*/}
            <div>
              <div className="brand-logo d-flex align-items-center justify-content-between">
                <Link to="/" className="text-nowrap logo-img">
                  <img src={logo} width={180} alt="." />
                </Link>
                <div
                  className="close-btn d-xl-none d-block sidebartoggler cursor-pointer"
                  id="sidebarCollapse"
                >
                  <i className="ti ti-x fs-8" />
                </div>
              </div>
              {/* Sidebar navigation*/}
              <nav className="sidebar-nav scroll-sidebar" data-simplebar>
                <ul id="sidebarnav">
                  <li className="sidebar-item">
                    <Link
                      to="/admin"
                      className="sidebar-link"
                      href="./index.html"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="ti ti-layout-dashboard" />
                      </span>
                      <span className="hide-menu">Home</span>
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link
                      to="/manage-user"
                      className="sidebar-link"
                      href="./ui-buttons.html"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="ti ti-article" />
                      </span>
                      <span className="hide-menu">Manage User</span>
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link
                      to="/manage-course"
                      className="sidebar-link"
                      href="./ui-alerts.html"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="ti ti-alert-circle" />
                      </span>
                      <span className="hide-menu">Manage Course</span>
                    </Link>
                  </li>
                </ul>
              </nav>
              {/* End Sidebar navigation */}
            </div>
            {/* End Sidebar scroll*/}
          </aside>
          {/*  Sidebar End */}
          {/*  Main wrapper */}
          <div className="body-wrapper">
            {/*  Header Start */}
            <header className="app-header">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div
                  className="navbar-collapse justify-content-end px-0"
                  id="navbarNav"
                >
                  <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link nav-icon-hover"
                        href="."
                        id="drop2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={profile}
                          alt=""
                          width={35}
                          height={35}
                          className="rounded-circle"
                        />
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up"
                        aria-labelledby="drop2"
                      >
                        <div className="message-body">
                          <a
                            href="."
                            className="d-flex align-items-center gap-2 dropdown-item"
                          >
                            <i className="ti ti-user fs-6" />
                            <p className="mb-0 fs-3">My Profile</p>
                          </a>
                          <a
                            href="."
                            className="d-flex align-items-center gap-2 dropdown-item"
                          >
                            <i className="ti ti-mail fs-6" />
                            <p className="mb-0 fs-3">My Account</p>
                          </a>
                          <a
                            href="."
                            className="d-flex align-items-center gap-2 dropdown-item"
                          >
                            <i className="ti ti-list-check fs-6" />
                            <p className="mb-0 fs-3">My Task</p>
                          </a>
                          <a
                            href="./authentication-login.html"
                            className="btn btn-outline-primary mx-3 mt-2 d-block"
                          >
                            Logout
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </header>
            {/*  Header End */}
            <div className="container-fluid">
              {/*  Row 1 */}
              <div className="row justify-content-center align-items-center">
                <h2 className="all-our-course text-center p-5">Courses</h2>
              </div>
              <div className="row">
                <div className="d-flex justify-content-end mb-3">
                  <button
                    className="add-btn me-2 align-self-end"
                    onClick={showAddCourseModal}
                  >
                    Add New Course
                  </button>
                </div>
                <div className="col-sm-6 col-xl-3">
                  <div className="card overflow-hidden rounded-2">
                    <div className="position-relative">
                      <a href=".">
                        <img
                          src={course2}
                          className="card-img-top rounded-0"
                          alt="..."
                        />
                      </a>
                    </div>
                    <div className="card-body d-flex flex-column justify-content-center align-items-center pt-3 p-4">
                      <h6 className="fw-semibold">Web Development</h6>
                      <span className="fw-light">By Barnabas Tekalign</span>
                      <div className="d-flex align-items-center justify-content-between">
                        <ul className="list-unstyled d-flex justify-content-between mb-0 mt-2">
                          <li>
                            <button
                              className="edit-btn me-2"
                              onClick={showUpdateModal}
                            >
                              Edit
                            </button>
                          </li>
                          <li>
                            <button className="delete-btn">Delete</button>
                          </li>
                          <li></li>
                          <li>
                            <a className="me-1" href=".">
                              <i className="ti ti-star text-warning" />
                            </a>
                          </li>
                          <li>
                            <a className href=".">
                              <i className="ti ti-star text-warning" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                  <div className="card overflow-hidden rounded-2">
                    <div className="position-relative">
                      <a href=".">
                        <img
                          src={course3}
                          className="card-img-top rounded-0"
                          alt="..."
                        />
                      </a>
                    </div>
                    <div className="card-body d-flex flex-column justify-content-center align-items-center pt-3 p-4">
                      <h6 className="fw-semibold">Web Development</h6>
                      <span className="fw-light">By Barnabas Tekalign</span>
                      <div className="d-flex align-items-center justify-content-between">
                        <ul className="list-unstyled d-flex justify-content-between mb-0 mt-2">
                          <li>
                            <button
                              className="edit-btn me-2"
                              onClick={showUpdateModal}
                            >
                              Edit
                            </button>
                          </li>
                          <li>
                            <button className="delete-btn">Delete</button>
                          </li>
                          <li></li>
                          <li>
                            <a className="me-1" href=".">
                              <i className="ti ti-star text-warning" />
                            </a>
                          </li>
                          <li>
                            <a className href=".">
                              <i className="ti ti-star text-warning" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                  <div className="card overflow-hidden rounded-2">
                    <div className="position-relative">
                      <a href=".">
                        <img
                          src={course4}
                          className="card-img-top rounded-0"
                          alt="..."
                        />
                      </a>
                    </div>
                    <div className="card-body d-flex flex-column justify-content-center align-items-center pt-3 p-4">
                      <h6 className="fw-semibold">Web Designing</h6>
                      <span className="fw-light">By Nasiha</span>
                      <div className="d-flex align-items-center justify-content-between">
                        <ul className="list-unstyled d-flex justify-content-between mb-0 mt-2">
                          <li>
                            <button
                              className="edit-btn me-2"
                              onClick={showUpdateModal}
                            >
                              Edit
                            </button>
                          </li>
                          <li>
                            <button className="delete-btn">Delete</button>
                          </li>
                          <li></li>
                          <li>
                            <a className="me-1" href=".">
                              <i className="ti ti-star text-warning" />
                            </a>
                          </li>
                          <li>
                            <a className href=".">
                              <i className="ti ti-star text-warning" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                  <div className="card overflow-hidden rounded-2">
                    <div className="position-relative">
                      <a href=".">
                        <img
                          src={course1}
                          className="card-img-top rounded-0"
                          alt="..."
                        />
                      </a>
                    </div>
                    <div className="card-body d-flex flex-column justify-content-center align-items-center pt-3 p-4">
                      <h6 className="fw-semibold">MERN Stack</h6>
                      <span className="fw-light">By Amina</span>
                      <div className="d-flex align-items-center justify-content-between">
                        <ul className="list-unstyled d-flex justify-content-between mb-0 mt-2">
                          <li>
                            <button
                              className="edit-btn me-2"
                              onClick={showUpdateModal}
                            >
                              Edit
                            </button>
                          </li>
                          <li>
                            <button className="delete-btn">Delete</button>
                          </li>
                          <li></li>
                          <li>
                            <a className="me-1" href=".">
                              <i className="ti ti-star text-warning" />
                            </a>
                          </li>
                          <li>
                            <a className href=".">
                              <i className="ti ti-star text-warning" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={addCourseModal}
        onHide={hideAddCourseModal}
        centered
        className="custom-modal modal-lg"
        size="lg"
        scrollable
      >
        <Modal.Header closeButton className="modal-header py-0 px-3">
          <Modal.Title className="modal-title fw-bolder fs-9 p-0 m-0 text-center w-100">
            Add course
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container">
            <Form>
              <Form.Group controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" onChange={(e)=>{setTitle(e.target.value)}} placeholder="Enter course title" />
              </Form.Group>

              <Form.Group controlId="instructor">
                <Form.Label>Instructor</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e)=>{setInstructor(e.target.value)}}
                  placeholder="Enter instructor's name"
                />
              </Form.Group>

              <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={(e)=>{setDescription(e.target.value)}}
                  placeholder="Enter course description"
                />
              </Form.Group>

              <Form.Group controlId="summary">
                <Form.Label>Summary</Form.Label>
                <Form.Control type="text" onChange={(e)=>{setSummary(e.target.value)}} placeholder="Enter course summary" />
              </Form.Group>

              <Form.Group controlId="price">
                <Form.Label>Price</Form.Label>
                <Form.Control type="number" onChange={(e)=>{setPrice(e.target.value)}} placeholder="Enter course price" />
              </Form.Group>

              <Form.Group controlId="priceDiscount">
                <Form.Label>Price Discount</Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e)=>{setPriceDiscount(e.target.value)}}
                  placeholder="Enter course discount price"
                />
              </Form.Group>

              <Form.Group controlId="category">
                <Form.Label>Category</Form.Label>
                <Form.Control type="text" onChange={(e)=>{setCategory(e.target.value)}} placeholder="Enter course category" />
              </Form.Group>

              <Form.Group controlId="coverImage">
                <Form.Label>Cover Image (Image File)</Form.Label>
                <Form.Control type="file" onChange={(e)=>{setImage(e.target.files[0])}} accept=".jpg, .jpeg, .png" />
              </Form.Group>

              <Form.Group controlId="previewVideo">
                <Form.Label>Preview Video (Video File)</Form.Label>
                <Form.Control type="file" onChange={(e)=>{setVideo(e.target.files[0])}} accept=".mp4"  />
              </Form.Group>

              <Form.Group controlId="courseFile">
                <Form.Label>Course File (ZIP)</Form.Label>
                <Form.Control type="file" onChange={(e)=>{setZipFile(e.target.files[0])}} accept=".zip" />
              </Form.Group>

              <div className="d-flex justify-content-center mt-4 mb-3">
                <Button
                  variant="warning"
                  className="w-75 align-self-center "
                  type="submit"
                  onClick={handleAddCourse}
                >
                  Add course
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateModal}
        onHide={closeUpdateModal}
        centered
        className="custom-modal modal-lg"
        size="lg"
        scrollable
      >
        <ModalHeader closeButton>
          <ModalTitle className="modal-title fw-bolder fs-9 p-0 m-0 text-center w-100">
            {" "}
            Edit course
          </ModalTitle>
        </ModalHeader>

        <ModalBody>
          <div className="container">
            <Form>
              <Form.Group controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" placeholder="Enter course title" />
              </Form.Group>

              <Form.Group controlId="instructor">
                <Form.Label>Instructor</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter instructor's name"
                />
              </Form.Group>

              <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter course description"
                />
              </Form.Group>

              <Form.Group controlId="summary">
                <Form.Label>Summary</Form.Label>
                <Form.Control type="text" placeholder="Enter course summary" />
              </Form.Group>

              <Form.Group controlId="price">
                <Form.Label>Price</Form.Label>
                <Form.Control type="number" placeholder="Enter course price" />
              </Form.Group>

              <Form.Group controlId="priceDiscount">
                <Form.Label>Price Discount</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter course discount price"
                />
              </Form.Group>

              <Form.Group controlId="category">
                <Form.Label>Category</Form.Label>
                <Form.Control type="text" placeholder="Enter course category" />
              </Form.Group>

              <Form.Group controlId="coverImage">
                <Form.Label>Cover Image (Image File)</Form.Label>
                <Form.Control type="file" accept=".jpg, .jpeg, .png" />
              </Form.Group>

              <Form.Group controlId="previewVideo">
                <Form.Label>Preview Video (Video File)</Form.Label>
                <Form.Control type="file" accept=".mp4" />
              </Form.Group>

              <Form.Group controlId="courseFile">
                <Form.Label>Course File (ZIP)</Form.Label>
                <Form.Control type="file" accept=".zip" />
              </Form.Group>

              <div className="d-flex justify-content-center mt-4 mb-3">
                <Button
                  variant="warning"
                  className="w-75 align-self-center "
                >
                  Update course
                </Button>
              </div>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ManageCourse;
