import { CART_URL } from "../constants";
import { apiSlice } from "./apiSlice";


export const cartApiSlice = apiSlice.injectEndpoints({
    
    endpoints:(builder) => ({
        addtoCart:builder.mutation({
            query:(data) =>({
                url:`${CART_URL}/add`,
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //  Authorization: `Bearer ${token}`
                    },
                body:JSON.stringify(data),
            }),
        }),
        
    }), 
});

export const {useAddtoCartMutation} = cartApiSlice