import React ,{useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import backgroundImage from "../../assets/img/teacher-image.png";
// import { Container } from 'react-bootstrap';
import styles from "./TeachWelcome.module.css";
import teachimg from "../../assets/img/teach.jpg";
import inspireimg from "../../assets/img/inspire.jpg";
import rewardimg from "../../assets/img/reward.jpg";
import supportimg from "../../assets/img/support.jpg";
import supportimg2 from "../../assets/img/support2.jpg";
import abelimg from "../../assets/img/abel.jpg";
import barnabasimg from "../../assets/img/barnabas.jpg";
import {
  Form,
  Modal,
  Button,
  ModalHeader,
  ModalTitle,
  ModalBody,
} from "react-bootstrap";

function TeachWelcome() {
 

  const [updateModal, setUpdateModal] = useState(false);
  const showUpdateModal = () => setUpdateModal(true);
  const closeUpdateModal = () => setUpdateModal(false);
  
  
  const divStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "220vh 100vh",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100vh",
  };

  return (
    <div className="TechWeolcome bg-white">

      <div className="" style={divStyle}>
        <div className="container pt-5">
          <div className="text-container pt-5 mt-5 d-inline-block">
            <h1 className={`content pt-5  fw-bolder ${styles.getStart}`}>
              Come Teach <br /> with us
            </h1>
            <h3>
              Become an instructor <br /> and change lives- including <br />
              your own
            </h3>
            <Button
              variant="warning"
              className={`py-3 ${styles.button}`}
              size="lg"
              onClick={showUpdateModal}
            >
              Get started
            </Button>
          </div>
        </div>
      </div>

      <div>
        <h2 className={` my-4 text-center  fw-bold ${styles.text}`}>
          
          So many reasons to start
        </h2>
        <div className="d-flex justify-content-between p-5 container">
          <div className="text-center px-3">
            <img
              className={` mx-auto d-block ${styles.imgsize1}`}
              src={teachimg}
              alt="."
            />
            <p className="fw-bolder fs-6 ">Teach your way</p>
            <p>
              
              Publish the course you want, in the way you want, and always have
              control of your own content.
            </p>
          </div>
          <div className="text-center  px-3">
            <img
              className={` mx-auto d-block ${styles.imgsize1}`}
              src={inspireimg}
              alt="."
            />
            <p className="fw-bolder fs-6">Inspire learners</p>
            <p>
              Teach what you know and help learners explore their interests,
              gain new skills, and advance their careers.
            </p>
          </div>
          <div className="text-center  px-3">
            <img
              className={`mx-auto d-block ${styles.imgsize1}`}
              src={rewardimg}
              alt="."
            />
            <p className="fw-bolder fs-5">Get rewarded</p>
            <p>
              Expand your professional network, build your expertise, and earn
              money on each paid enrollment.
            </p>
          </div>
        </div>
      </div>

      <div className="warning">
        <div className=" d-flex bg-warning flex-wrap justify-content-between py-4 px-5 text-white my-3">
          <div className="text-center">
            <h2 className="fw-bold  my-0"> 62k</h2>
            <p className="fs-5">Students</p>
          </div>
          <div className="text-center">
            <h2 className="fw-bold  my-0"> 30+</h2>
            <p className="fs-5">Languages</p>
          </div>
          <div className="text-center">
            <h2 className="fw-bold my-0"> 830k</h2>
            <p className="fs-5">Enrollment</p>
          </div>
          <div className="text-center">
            <h2 className="fw-bold  my-0"> 15+</h2>
            <p className="fs-5">Countries</p>
          </div>
          <div className="text-center">
            <h2 className="fw-bold my-0 "> 6k +</h2>
            <p className="fs-5 ">Enterprise custromers</p>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between py-5">
        <img
          className={` float-start pl-0 ml-0 ${styles.supportimg}`}
          src={supportimg}
          alt="."
        />
        <div className="text-center mt-4 pt-4 ">
          <h4 className="fw-bold"> You won't have to do it alone</h4>
          <p>
            Our Instructor Support Team is here to answer your questions <br />
            and review your test video, while <br />
            our Teaching Center gives you plenty of resources to help
            <br />
            you through the process. Plus, get the support of
            <br />
            experience instructors in our online community.
          </p>
        </div>
        <img
          className={` float-end pr-0 mr-0 ${styles.supportimg}`}
          src={supportimg2}
          alt="."
        />
      </div>

      <div className="testimonials bg-#f7f9fa">
        <div className="container">
          <div id="testimonialsCarousel" className="carousel slide">
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#testimonialsCarousel"
                data-bs-slide-to={0}
                className="active indicator-dot"
                aria-current="true"
                aria-label="Slide 1"
              />
              <button
                type="button"
                data-bs-target="#testimonialsCarousel"
                data-bs-slide-to={1}
                className="indicator-dot"
                aria-label="Slide 2"
              />
            </div>
            <div className="carousel-inner">
              <div className={`carousel-item active ${styles.darkbackground}`}>
                <div className="d-flex flex-wrap justify-content-between px-4 text-center">
                  <img
                    className={`mx-auto my-auto ${styles.instimg}`}
                    src={abelimg}
                    alt="."
                  />
                  <div className="blockquotes p-5 mt-5 ml-5">
                    <p className={` fs-6  ${styles.instimg}`}>
                      
                      “I’m proud to wake up knowing my work is helping people
                      around the world improve their careers and build great
                      things. While being a full-time instructor is hard work,
                      it lets you work when, where, and how you want.”
                    </p>
                    <p className="fw-bold text-start mx-3">Abel</p>
                    <footer className="blockquote-footer text-start ml-5 pl-5">
                      frontend designer
                    </footer>
                  </div>
                </div>
              </div>
              <div className={`carousel-item ${styles.darkbackground}`}>
                <div className="d-flex flex-wrap justify-content-between px-4 text-center">
                  <img
                    className={`mx-auto my-auto ${styles.instimg}`}
                    src={barnabasimg}
                    alt="."
                  />
                  <div className="blockquotes p-5 mt-5 ml-5">
                    <p className={` fs-6  ${styles.instimg}`}>
                      
                      “Ulearn has changed my life. It’s allowed me to follow my
                      passion and become a teacher I love to see my students
                      succeed and hear them say they’ve learned more, quicker,
                      from my courses than they did in college. It’s so
                      humbling.”
                    </p>
                    <p className="fw-bold text-start mx-3">Barnabas</p>
                    <footer className="blockquote-footer text-start ml-5 pl-5">
                      Backend designer
                    </footer>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#testimonialsCarousel"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#testimonialsCarousel"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>

      <div className="container justify-content-center d-flex pb-5">
        <div className="d-inline-block mb-5 text-center">
          <h2 className="fw-bold">Become an instructor today</h2>
          <p> Join one of the world’s largest online learning marketplaces.</p>
          <Button
            className={`py-3 ${styles.button}`}
            variant="warning"
            size="lg"
            onClick={showUpdateModal}
          >
            
            Register
          </Button>
        </div>
      </div>

      <Modal
        show={updateModal}
        onHide={closeUpdateModal}
        centered
        className="custom-modal modal-lg"
        size="lg"
        scrollable
      >
        <ModalHeader closeButton>
          <ModalTitle className="modal-title fw-bolder fs-9 p-0 m-0 text-center w-100">      
            Get Started
          </ModalTitle>
        </ModalHeader>

        <ModalBody>
          <div className="container">
            <Form>
              <Form.Group controlId="name row">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter your name" />
              </Form.Group>
              <Form.Group controlId="email row">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="example@gmail.com" />
              </Form.Group>
              <Form.Group controlId="password row">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Enter password" />
              </Form.Group>
              <Form.Group controlId="password row">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="password" placeholder="confirm" />
              </Form.Group>

              <div className="row  justify-content-center mt-4 mb-3">
                <Button
                  variant="warning"
                  className="align-self-center col-lg-3 "
                >
                 Register
                </Button>
              </div>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default TeachWelcome;
