import React from "react";
import { Link , useNavigate } from "react-router-dom";
import { useDispatch ,useSelector } from "react-redux";
import course2 from "../../assets/img/course2.jpg";

import { addToCart } from "../../slice/cartSlice";
import { useAddtoCartMutation } from "../../slice/cartApiSlice";
import {selectCurrentToken} from '../../slice/authSlice';




import Star from "../Star";
const Course = ({ course }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addtocart] = useAddtoCartMutation ();
  const token = useSelector(selectCurrentToken);


  const addToCartHandler = async() => {
    try{
    dispatch(addToCart({ ...course }));


    const Nav = document.getElementById("navBar");
    if (Nav) {
      Nav.scrollIntoView({ behavior: "smooth" });
    }

    const courseId = course.id;
    const response = await addtocart({course}, {
      onQueryStarted: (request) => {  
        request.headers.set('Authorization', `Bearer ${token}`);
      },
    });

    navigate('/CartList')

    console.log(courseId)
    console.log(response)
  } catch(error){
    console.error("Error adding course to cart:", error);
  }


  };

  return (
    <div className="col-lg-3 col-md-6 col-sm-6">
      <div className="card">
        <Link to={`/course/${course.id}`}>
          {/* <img src={course.coverImage} className="card-img-top" alt="..." /> */}
          <img src={course2} className="card-img-top" alt="..." />
        </Link>
        <div className="card-body p-2">
          <div className="course-title-in-card">
            <Link to={`/course/${course.id}`}>
              <h5 className="card-title text-decoration-none fw-bold">
                {course.title}
              </h5>
            </Link>
          </div>
          <div className="course-info d-flex">
            <p className="instructor-name text-nowrap me-2">
              By {course.instructor.name}
            </p>
          </div>
          <div className="course-rate mb-3">
            <Star stars={4.7} reviews={215778} />
          </div>
          <div className="enrolled-students col-lg-12">
            <span className="number fw-bold col-lg-12 text-nowrap">400</span>{" "}
            Students have enrolled
          </div>
          <div className="add-to-cart d-flex justify-content-center align-items-center">
            <button
              className="add-to-cart-btn text-nowrap btn"
              onClick={addToCartHandler}
            >
              Add to cart
              <span className="cart-icon">
                <i className="fas fa-cart-shopping ms-1"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
