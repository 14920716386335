import React from 'react'
import { useEffect ,useState } from 'react';
import {keyframes } from '@emotion/react';
import { FaGraduationCap } from 'react-icons/fa';


const loadingText = keyframes`
  0% {
    color:#F6C90E;
  }
  33% {
    color: #F6C90E;
  }
  66% {
    color: #F6C90E;
  }
  100% {
    color: #F6C90E;
  }
`;

const Loader = () => {

    const [dots, setDots] = useState('');

    useEffect(() => {
      const interval = setInterval(() => {
        setDots(dots => dots.length < 3 ? dots + '.' : '');
      }, 500);
  
      return () => clearInterval(interval);
    }, []);
  return (
    <div className="loader-div text-center w-100 p-5 h-100">
      <div className='w-100 p-5'>
        <FaGraduationCap size={200} color={'#F6C90E'} />
      </div>

      <h1 className='fw-bolder' css={{ animation: `${loadingText} 1.5s infinite` }}>U-learn<span id='three-dot'>{dots}</span></h1>
    </div>
  )
}

export default Loader
