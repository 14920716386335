import React from 'react'
import { Link } from 'react-router-dom'
import "../systemadmin.css";
import profile from "../assets/profile/user-1.jpg";
import logo from "../assets/img/logo.png";
import course1 from "../assets/img/course1.jpg";
import course2 from "../assets/img/course2.jpg";
import course3 from "../assets/img/test1.jpg";
import course4 from "../assets/img/test2.jpg";
function Instructor() {
  return (
    <div className="system-admin-dashboard bg-white">
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        {/* Sidebar Start */}
        <aside className="left-sidebar">
          {/* Sidebar scroll*/}
          <div>
            <div className="brand-logo d-flex align-items-center justify-content-between">
              <Link to='/instructor' className="text-nowrap logo-img">
                <img src={logo} width={180} alt="Logoooo" />
              </Link>
              <div
                className="close-btn d-xl-none d-block sidebartoggler cursor-pointer"
                id="sidebarCollapse"
              >
                <i className="ti ti-x fs-8" />
              </div>
            </div>
            {/* Sidebar navigation*/}
            <nav className="sidebar-nav scroll-sidebar" data-simplebar>
              <ul id="sidebarnav">
                <li className="sidebar-item">
                  <Link to='/instructor'
                    className="sidebar-link"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="ti ti-layout-dashboard" />
                    </span>
                    <span className="hide-menu"><i className='bi  bi-house fs-8 text-warning px-4 py-4'></i>Home</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to='/course-management'
                    className="sidebar-link"
                    href="./ui-buttons.html"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="ti ti-article" />
                    </span>
                    <span className="hide-menu">Course Management</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to='/course-analytics'
                    className="sidebar-link"
                    href="./ui-alerts.html"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="ti ti-alert-circle" />
                    </span>
                    <span className="hide-menu">Course Analytics</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to='/discussion-forum'
                    className="sidebar-link"
                    href="./ui-alerts.html"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="ti ti-alert-circle" />
                    </span>
                    <span className="hide-menu">Discussion Forum</span>
                  </Link>
                </li>
              </ul>
            </nav>
            {/* End Sidebar navigation */}
          </div>
          {/* End Sidebar scroll*/}
        </aside>
        {/*  Sidebar End */}
        {/*  Main wrapper */}
        <div className="body-wrapper">
          {/*  Header Start */}
          <header className="app-header">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div
                className="navbar-collapse justify-content-end px-0"
                id="navbarNav"
              >
                <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link nav-icon-hover"
                      href="."
                      id="drop2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={profile}
                        alt=""
                        width={35}
                        height={35}
                        className="rounded-circle"
                      />
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up"
                      aria-labelledby="drop2"
                    >
                      <div className="message-body">
                        <a
                          href="."
                          className="d-flex align-items-center gap-2 dropdown-item"
                        >
                          <i className="ti ti-user fs-6" />
                          <p className="mb-0 fs-3">My Profile</p>
                        </a>
                        <a
                          href="./authentication-login.html"
                          className="btn btn-outline-primary mx-3 mt-2 d-block"
                        >
                          Logout
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </header>
          {/*  Header End */}

          <div className="container-fluid">
            <div className="row">
              <h5 className="fw-semibold mb-4 ms-4">
                Most Rated Course
              </h5>
              <div className="col-sm-6 col-xl-3">
                <div className="card overflow-hidden rounded-2">
                  <div className="position-relative">
                    <a href=".">
                      <img
                        src={course2}
                        className="card-img-top rounded-0"
                        alt="..."
                      />
                    </a>
                  </div>
                  <div className="card-body d-flex flex-column justify-content-center align-items-center pt-3 p-4">
                    <h6 className="fw-semibold">Web Development</h6>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="fw-light">
                        By Barnabas Tekalign
                      </h6>
                      <ul className="list-unstyled d-flex align-items-center mb-0">
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="card overflow-hidden rounded-2">
                  <div className="position-relative">
                    <a href=".">
                      <img
                        src={course3}
                        className="card-img-top rounded-0"
                        alt="..."
                      />
                    </a>
                  </div>
                  <div className="card-body d-flex flex-column justify-content-center align-items-center pt-3 p-4">
                    <h6 className="fw-semibold">Web Development</h6>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="fw-light">
                        By Barnabas Tekalign
                      </h6>
                      <ul className="list-unstyled d-flex align-items-center mb-0">
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="card overflow-hidden rounded-2">
                  <div className="position-relative">
                    <a href=".">
                      <img
                        src={course4}
                        className="card-img-top rounded-0"
                        alt="..."
                      />
                    </a>
                  </div>
                  <div className="card-body d-flex flex-column justify-content-center align-items-center pt-3 p-4">
                    <h6 className="fw-semibold">Web Designing</h6>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="fw-light">
                        By Nasiha
                      </h6>
                      <ul className="list-unstyled d-flex align-items-center mb-0">
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3">
                <div className="card overflow-hidden rounded-2">
                  <div className="position-relative">
                    <a href=".">
                      <img
                        src={course1}
                        className="card-img-top rounded-0"
                        alt="..."
                      />
                    </a>
                  </div>
                  <div className="card-body d-flex flex-column justify-content-center align-items-center pt-3 p-4">
                    <h6 className="fw-semibold">MERN Stack</h6>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="fw-light">
                        By Amina
                      </h6>
                      <ul className="list-unstyled d-flex align-items-center mb-0">
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className="me-1" href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                        <li>
                          <a className href=".">
                            <i className="ti ti-star text-warning" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>


        </div>
      </div>


      )
}

      export default Instructor