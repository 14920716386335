import React from "react";
import footLogo from "../../assets/img/logo-at-footer.png";
import fb from "../../assets/icons/social-icons/facebook.svg";
import twitter from "../../assets/icons/social-icons/twitter.svg";
import youtube from "../../assets/icons/social-icons/youtube.svg";
import telegram from "../../assets/icons/social-icons/telegram.svg";
import { Link } from "react-router-dom";
// import TermAndCondition from '../../pages/TermAndCondition'

const Footer = () => {
  return (
    <div className="footer-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 d-flex flex-column justify-content-center">
            <div className="logo-at-footer mb-2">
              <img src={footLogo} alt="no" />
            </div>
            <div className="who-we-are text-start">
              Ulearn is an online education platform that delivers video
              courses, programs and resources In Ethiopia.
            </div>
            <div className="d-flex mb-5 mt-5 social-links">
              <Link to="/" className="fb me-4">
                <img src={fb} alt="no" />
              </Link>
              <Link to="/" className="twitter me-4">
                <img src={twitter} alt="no" />
              </Link>
              <Link className="youtube me-4">
                <img src={youtube} alt="no" />
              </Link>
              <Link className="telegram me-4">
                <img src={telegram} alt="no" />
              </Link>
            </div>
          </div>
          <div className="col-lg-3 d-flex flex-column justify-content-center">
            <h4 className="col-title text-start ms-5 ps-5 mb-3">Quicklinks</h4>
            <ul className="links list-unstyled d-flex flex-column justify-content-center ms-5 ps-5">
              <li className="link text-start">Home</li>
              <li className="link text-start">Courses</li>
              <li className="link text-start">About Us</li>
              <li className="link text-start nav-item">
                <Link to="/Contactus" className="nav-link" aria-current="page">
                  Contactus
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 d-flex flex-column justify-content-center">
            <h4 className="col-title text-start ms-5 ps-5 mb-3 mt-4 text-nowrap">
              Contact Us
            </h4>
            <ul className="contact-through list-unstyled d-flex flex-column justify-content-center ms-5 ps-5">
              <li className="phone text-start">+251934535435</li>
              <li className="gmail text-start">ulearnethiopia@gmail.com</li>
              <li className="location text-start">
                Ethiopia ,Addis Ababa,Meskel Flower ,Abebech Tower
              </li>
            </ul>
          </div>
          <div className="col-lg-3 terms-and-conditions d-flex flex-column justify-content-center">
            <Link to={"/TermAndCondition"}>
              <p>Terms and Conditions</p>
            </Link>
            <p>FAQ</p>
          </div>
        </div>
      </div>
      <div className="who-develop-ulearn mt-4">
        <p>Developed By Qemer software plc | Lion Team.</p>
      </div>
    </div>
  );
};

export default Footer;
