import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { selectCurrentToken } from "../../slice/authSlice";

import { setCredentials } from "../../slice/authSlice";
import { useLoginMutation } from "../../slice/usersApiSlice";
import { logout } from "../../slice/authSlice";
import { useRegisterMutation } from "../../slice/usersApiSlice";

import { Modal } from "react-bootstrap";
import { Nav, Tab } from "react-bootstrap";
import "./Navbar.css";
import logo from "../../assets/img/logo.png";
import searchIcon from "../../assets/icons/bi_search.svg";
import cartIcon from "../../assets/icons/mdi_cart-outline.svg";
import heartIcon from "../../assets/icons/heart.svg";
import globeIcon from "../../assets/icons/bi_globe.svg";
import facebook from "../../assets/icons/social-icons/facebook.svg";
import google from "../../assets/icons/social-icons/google.svg";
import linkedin from "../../assets/icons/social-icons/linkedin.svg";
import { useState } from "react";

const Navbar = () => {
  const { coursesInCart } = useSelector((state) => state.cart);
  // ====Start:input for registering user ===
  const nameRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmpasswordRef = useRef(null);
  const userNameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneNumberRef = useRef(null);
  // ==== End

  // ====Start:input for login user ===
  const loginEmailRef = useRef(null);
  const loginPasswordRef = useRef(null);

  // ==== End

  const [register] = useRegisterMutation();
  const [login] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [token, setToken] = useState(useSelector(selectCurrentToken));

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };
  // === Start:Method for handling signup request
  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const name = nameRef.current.value;
      const password = passwordRef.current.value;
      const passwordConfirm = confirmpasswordRef.current.value;
      const username = userNameRef.current.value;
      const email = emailRef.current.value;
      const phoneNumber = phoneNumberRef.current.value;
      console.log(name, username);
      const response = await register({
        name,
        password,
        passwordConfirm,
        username,
        email,
        phoneNumber,
      }).unwrap();
      console.log("Registration successful:", response.data);
    } catch (error) {
      console.error("Error during registration:", error);
    }
  };
  // === End:Method for handling signup request

  // === Start:Method for handling login request
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const email = loginEmailRef.current.value;
      const password = loginPasswordRef.current.value;
      console.log(email, password);
      const response = await login({
        email,
        password,
      }).unwrap();
      dispatch(setCredentials(response.data.token));

      console.log(response.data.token);

      setToken(response.data.token);

      navigate("/");
      setShowModal(false);

      console.log("login succeed:", response);
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  // === End:Method for handling login request
  // === Start:Logout start
  const handleLogout = () => {
    dispatch(logout());
    setToken(null);
  };
  // === End: Logout end
  return (
    <>
      <nav className="navbar navbar-expand-lg" id="navBar">
        <div className="container d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <a className="navbar-brand mt-1" href=".">
              <img src={logo} alt="Ulearn Logo" width="100" />
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto gx-4">
              <form className="d-flex ms-2 me-3">
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Search for courses"
                  aria-label="Search"
                />
              </form>
              <li className="nav-item">
                <Link to="/TeachOnUs" className="nav-link text-nowrap">
                  Teach On Us
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/my-course" className="nav-link text-nowrap">
                  My Courses
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/CartList"
                  className="nav-link text-nowrap position-relative me-2"
                >
                  <img src={cartIcon} alt="Cart" width="24" className="" />
                  {coursesInCart.length > 0 ? (
                    <span className="cart-counter position-absolute fw-bolder text-white bottom-50 transate-middle rounded">
                      {coursesInCart.length}
                    </span>
                  ) : (
                    ""
                  )}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/WishList" className="nav-link">
                  <img src={heartIcon} alt="Wishlist" width="24" />
                </Link>
              </li>
            </ul>
            {token ? (
              <div className="px-4 ms-5 me-5">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    {token.slice(0, 5)}
                    <button onClick={handleLogout} className="me-2">Logout</button>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href=".">
                      <img src={globeIcon} alt="Language" width="24" />
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              <ul className="navbar-nav">
                <li className="nav-item">
                  <button
                    className="btn me-2"
                    id="login-btn"
                    onClick={handleShowModal}
                  >
                    Login
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="btn me-2"
                    id="signup-btn"
                    onClick={handleShowModal}
                  >
                    Signup
                  </button>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href=".">
                    <img src={globeIcon} alt="Language" width="24" />
                  </a>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        className="custom-modal modal-lg"
        size="lg"
        scrollable
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="modal-title text-center w-100">
            Signup for free
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* Tab.Container component */}
          <Tab.Container activeKey={activeTab}>
            {/* Nav component */}
            <Nav justify variant="tabs" defaultActiveKey="tab1">
              <Nav.Item>
                <Nav.Link
                  eventKey="tab1"
                  active={activeTab === "tab1"}
                  onClick={() => handleTabSelect("tab1")}
                  className="custom-nav-item "
                >
                  Signup
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="tab2"
                  active={activeTab === "tab2"}
                  onClick={() => handleTabSelect("tab2")}
                  className="custom-nav-item"
                >
                  Login
                </Nav.Link>
              </Nav.Item>
            </Nav>

            {/* Tab.Content component */}
            <Tab.Content>
              <Tab.Pane eventKey="tab1" className="custom-tab-pane">
                <div className="mt-3">
                  <div className="row">
                    <div className="col-lg-5 left-part social-media mt-3">
                      <div className="row g-4 p-3">
                        <div className="col-lg-12 through-facebook">
                          <img src={facebook} alt="no" className="me-4" />
                          <span>Login with facebook</span>
                        </div>
                        <div className="col-lg-12 through-google">
                          <img src={google} alt="no" className="me-4" />
                          <span>Login with facebook</span>
                        </div>
                        <div className="col-lg-12 through-linkedin">
                          <img src={linkedin} alt="no" className="me-4" />
                          <span>Login with facebook</span>
                        </div>
                        <div className="col-lg-12 mt-5">
                          Don't have an account? <span>Signup</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 splitter d-flex flex-column">
                      <div className="line-above-or"></div>
                      <p className="or-text d-flex justify-content-center align-items-center ms-2">
                        OR
                      </p>
                      <div className="line-below-or"></div>
                    </div>
                    <div className="right-part col-lg-5">
                      <form onSubmit={handleSignup} className="row">
                        <p className="col-lg-12">
                          This is the name that appears on your certifications
                        </p>
                        <div className="row">
                          <div className="col-lg-12">
                            <div>
                              <label htmlFor="fullname" className="form-label">
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                ref={nameRef}
                                placeholder="full name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div>
                              <label htmlFor="password" className="form-label">
                                Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                ref={passwordRef}
                                placeholder="password"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div>
                              <label
                                htmlFor="confirmPassword"
                                className="form-label"
                              >
                                Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                ref={confirmpasswordRef}
                                placeholder="confirm"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div>
                              <label htmlFor="username" className="form-label">
                                User Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                ref={userNameRef}
                                placeholder="username"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div>
                              <label htmlFor="email" className="form-label">
                                Email
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                ref={emailRef}
                                placeholder="example@gmail.com"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div>
                              <label htmlFor="firsname" className="form-label">
                                Phone Number
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                ref={phoneNumberRef}
                                placeholder="09234567"
                              />
                            </div>
                          </div>
                          <div className="signup-btn">
                            <button
                              className="col-lg-6 col-sm-3 ms-5 mt-3"
                              id="signup-btn-in-modal"
                            >
                              Sign up
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="tab2">
                <div className="mt-3">
                  <div className="row">
                    <div className="col-lg-5 left-part social-media mt-3">
                      <div className="row g-4 p-3">
                        <div className="col-lg-12 through-facebook">
                          <img src={facebook} alt="no" className="me-4" />
                          <span>Login with facebook</span>
                        </div>
                        <div className="col-lg-12 through-google">
                          <img src={google} alt="no" className="me-4" />
                          <span>Login with facebook</span>
                        </div>
                        <div className="col-lg-12 through-linkedin">
                          <img src={linkedin} alt="no" className="me-4" />
                          <span>Login with facebook</span>
                        </div>
                        <div className="col-lg-12 mt-5">
                          Don't have an account? <span>Signup</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 splitter d-flex flex-column">
                      <div className="line-above-or"></div>
                      <p className="or-text d-flex justify-content-center align-items-center ms-2">
                        OR
                      </p>
                      <div className="line-below-or"></div>
                    </div>
                    <div className="right-part col-lg-5">
                      <div className="row">
                        <form onSubmit={handleLogin}>
                          <div className="row email-and-password mt-2">
                            <div className="email-input-box col-lg-12">
                              <div>
                                <label htmlFor="email" className="form-label">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  ref={loginEmailRef}
                                  placeholder="abel@gmail.com"
                                />
                              </div>
                            </div>
                            <div className="password-input-box col-lg-12">
                              <div>
                                <label
                                  htmlFor="password"
                                  className="form-label"
                                >
                                  Password
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  ref={loginPasswordRef}
                                  placeholder="...."
                                />
                              </div>
                            </div>
                            <p className="col-lg-12">
                              <input type="checkbox" />
                              <span className="ms-2">
                                I agree to the terms and conditions
                              </span>
                            </p>
                            <button
                              className="col-lg-6 col-sm-3 ms-5"
                              id="login-btn-in-modal"
                            >
                              Login
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
