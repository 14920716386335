import React from "react";
import { Button, Card, Col, Nav, Row } from "react-bootstrap";
import Star from "../components/Star";
import Footer from "../components/home/Footer";
import Navbar from "../components/home/Navbar";

function WishList() {
  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
      }}
    >
      <Navbar />

      <Nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <div className="d-flex ms-auto">
            <form
              className="d-flex"
              style={{
                padding: "5px",
                backgroundColor: "#f5f5f5",
                borderRadius: "20px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                fontSize: "106px",
                color: "#333",
              }}
            >
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search my courses"
              />
              <Button
                variant="outline-warning"
                type="submit"
                style={{
                  padding: "5px ",
                  fontSize: "16px",
                  borderRadius: "10px",
                  color: "#333",
                }}
              >
                Search
              </Button>
            </form>
          </div>
        </div>
      </Nav>
      <div
        className="d-flex flex-wrap container"
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <Row xs={1} md={3} lg={4} className="g-4">
          <Col className="g-5" xs={12} md={6} lg={4}>
            <Card className="card" style={{ height: "350px" }}>
              <Card.Img
                style={{
                  height: "140px",
                  objectFit: "cover",
                }}
                variant="top"
                src="https://th.bing.com/th/id/OIP.A4KletiN6ejZPIx3-tkpVAHaEK?pid=ImgDet&rs=1"
              />
              <Card.Body>
                <Card.Title
                  as="h3"
                  style={{
                    marginTop: "-25px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    fontSize: "16px",
                  }}
                >
                  Data Structure and Algorithm using C++
                </Card.Title>
                <Card.Text>
                  <Card.Subtitle
                    className="text-black-50 opacity-50 fs-6 my-0"
                    style={{ marginTop: "10px" }}
                  >
                    MM2
                  </Card.Subtitle>
                  <Star stars={4.7} reviews={215778} />
                  <ul
                    className="list-group list-group-horizontal my-0"
                    style={{ fontSize: "12px", marginLeft: "-17px" }}
                  >
                    <li className="list-group-item disabled border-0">
                      13 total hours
                    </li>
                    <li className="list-group-item disabled border-0 ">
                      . 92 lectures
                    </li>
                  </ul>
                  <div className="mt-0">
                    <p className="d-inline-block fs-4 fw-bold ">50.97 Birr</p>
                    <p className="d-inline-block text-decoration-line-through fs-4 opacity-50 mx-4">
                      169.56 Birr
                    </p>
                  </div>
                  <Button
                    variant="warning px-3 py-0"
                    style={{ position: "relative", top: "-10px" }}
                  >
                    Bestseller
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col className="g-5" xs={12} md={6} lg={4}>
            <Card className="card" style={{ height: "350px" }}>
              <Card.Img
                style={{
                  height: "140px",
                  objectFit: "cover",
                }}
                variant="top"
                src="https://th.bing.com/th/id/OIP.A4KletiN6ejZPIx3-tkpVAHaEK?pid=ImgDet&rs=1"
              />
              <Card.Body>
                <Card.Title
                  as="h3"
                  style={{
                    marginTop: "-25px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    fontSize: "16px",
                  }}
                >
                  Data Structure and Algorithm using C++
                </Card.Title>
                <Card.Text>
                  <Card.Subtitle
                    className="text-black-50 opacity-50 fs-6 my-0"
                    style={{ marginTop: "10px" }}
                  >
                    MM2
                  </Card.Subtitle>
                  <Star stars={4.7} reviews={215778} />
                  <ul
                    className="list-group list-group-horizontal my-0"
                    style={{ fontSize: "12px", marginLeft: "-17px" }}
                  >
                    <li className="list-group-item disabled border-0">
                      13 total hours
                    </li>
                    <li className="list-group-item disabled border-0 ">
                      . 92 lectures
                    </li>
                  </ul>
                  <div className="mt-0">
                    <p className="d-inline-block fs-4 fw-bold ">50.97 Birr</p>
                    <p className="d-inline-block text-decoration-line-through fs-4 opacity-50 mx-4">
                      169.56 Birr
                    </p>
                  </div>
                  <Button
                    variant="warning px-3 py-0"
                    style={{ position: "relative", top: "-10px" }}
                  >
                    Bestseller
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="g-5" xs={12} md={6} lg={4}>
            <Card className="card" style={{ height: "350px" }}>
              <Card.Img
                style={{
                  height: "140px",
                  objectFit: "cover",
                }}
                variant="top"
                src="https://th.bing.com/th/id/OIP.A4KletiN6ejZPIx3-tkpVAHaEK?pid=ImgDet&rs=1"
              />
              <Card.Body>
                <Card.Title
                  as="h3"
                  style={{
                    marginTop: "-25px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    fontSize: "16px",
                  }}
                >
                  Data Structure and Algorithm using C++
                </Card.Title>
                <Card.Text>
                  <Card.Subtitle
                    className="text-black-50 opacity-50 fs-6 my-0"
                    style={{ marginTop: "10px" }}
                  >
                    MM2
                  </Card.Subtitle>
                  <Star stars={4.7} reviews={215778} />
                  <ul
                    className="list-group list-group-horizontal my-0"
                    style={{ fontSize: "12px", marginLeft: "-17px" }}
                  >
                    <li className="list-group-item disabled border-0">
                      13 total hours
                    </li>
                    <li className="list-group-item disabled border-0 ">
                      . 92 lectures
                    </li>
                  </ul>
                  <div className="mt-0">
                    <p className="d-inline-block fs-4 fw-bold">50.97 Birr</p>
                    <p className="d-inline-block text-decoration-line-through fs-4 opacity-50 mx-4">
                      169.56 Birr
                    </p>
                  </div>
                  <Button
                    variant="warning px-3 py-0"
                    style={{ position: "relative", top: "-10px" }}
                  >
                    Bestseller
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="g-5" xs={12} md={6} lg={4}>
            <Card className="card" style={{ height: "350px" }}>
              <Card.Img
                style={{
                  height: "140px",
                  objectFit: "cover",
                }}
                variant="top"
                src="https://th.bing.com/th/id/OIP.A4KletiN6ejZPIx3-tkpVAHaEK?pid=ImgDet&rs=1"
              />
              <Card.Body>
                <Card.Title
                  as="h3"
                  style={{
                    marginTop: "-25px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    fontSize: "16px",
                  }}
                >
                  Data Structure and Algorithm using C++
                </Card.Title>
                <Card.Text>
                  <Card.Subtitle
                    className="text-black-50 opacity-50 fs-6 my-0"
                    style={{ marginTop: "10px" }}
                  >
                    MM2
                  </Card.Subtitle>
                  <Star stars={4.7} reviews={215778} />
                  <ul
                    className="list-group list-group-horizontal my-0"
                    style={{ fontSize: "12px", marginLeft: "-17px" }}
                  >
                    <li className="list-group-item disabled border-0">
                      13 total hours
                    </li>
                    <li className="list-group-item disabled border-0 ">
                      . 92 lectures
                    </li>
                  </ul>
                  <div className="mt-0">
                    <p className="d-inline-block fs-4 fw-bold">50.97 Birr</p>
                    <p className="d-inline-block text-decoration-line-through fs-4 opacity-50 mx-4">
                      169.56 Birr
                    </p>
                  </div>
                  <Button
                    variant="warning px-3 py-0"
                    style={{ position: "relative", top: "-10px" }}
                  >
                    Bestseller
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="g-5" xs={12} md={6} lg={4}>
            <Card className="card" style={{ height: "350px" }}>
              <Card.Img
                style={{
                  height: "140px",
                  objectFit: "cover",
                }}
                variant="top"
                src="https://th.bing.com/th/id/OIP.A4KletiN6ejZPIx3-tkpVAHaEK?pid=ImgDet&rs=1"
              />
              <Card.Body>
                <Card.Title
                  as="h3"
                  style={{
                    marginTop: "-25px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    fontSize: "16px",
                  }}
                >
                  Data Structure and Algorithm using C++
                </Card.Title>
                <Card.Text>
                  <Card.Subtitle
                    className="text-black-50 opacity-50 fs-6 my-0"
                    style={{ marginTop: "10px" }}
                  >
                    MM2
                  </Card.Subtitle>
                  <Star stars={4.7} reviews={215778} />
                  <ul
                    className="list-group list-group-horizontal my-0"
                    style={{ fontSize: "12px", marginLeft: "-17px" }}
                  >
                    <li className="list-group-item disabled border-0">
                      13 total hours
                    </li>
                    <li className="list-group-item disabled border-0 ">
                      . 92 lectures
                    </li>
                  </ul>
                  <div className="mt-0">
                    <p className="d-inline-block fs-4 fw-bold">50.97 Birr</p>
                    <p className="d-inline-block text-decoration-line-through fs-4 opacity-50 mx-4">
                      169.56 Birr
                    </p>
                  </div>
                  <Button
                    variant="warning px-3 py-0"
                    style={{ position: "relative", top: "-10px" }}
                  >
                    Bestseller
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col className="g-5" xs={12} md={6} lg={4}>
            <Card className="card" style={{ height: "350px" }}>
              <Card.Img
                style={{
                  height: "140px",
                  objectFit: "cover",
                }}
                variant="top"
                src="https://th.bing.com/th/id/OIP.A4KletiN6ejZPIx3-tkpVAHaEK?pid=ImgDet&rs=1"
              />
              <Card.Body>
                <Card.Title
                  as="h3"
                  style={{
                    marginTop: "-25px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    fontSize: "16px",
                  }}
                >
                  Data Structure and Algorithm using C++
                </Card.Title>
                <Card.Text>
                  <Card.Subtitle
                    className="text-black-50 opacity-50 fs-6 my-0"
                    style={{ marginTop: "10px" }}
                  >
                    MM2
                  </Card.Subtitle>
                  <Star stars={4.7} reviews={215778} />
                  <ul
                    className="list-group list-group-horizontal my-0"
                    style={{ fontSize: "12px", marginLeft: "-17px" }}
                  >
                    <li className="list-group-item disabled border-0">
                      13 total hours
                    </li>
                    <li className="list-group-item disabled border-0 ">
                      . 92 lectures
                    </li>
                  </ul>
                  <div className="mt-0">
                    <p className="d-inline-block fs-4 fw-bold">50.97 Birr</p>
                    <p className="d-inline-block text-decoration-line-through fs-4 opacity-50 mx-4">
                      169.56 Birr
                    </p>
                  </div>
                  <Button
                    variant="warning px-3 py-0"
                    style={{ position: "relative", top: "-10px" }}
                  >
                    Bestseller
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <Footer />
    </div>
  );
}

export default WishList;
