   /* === What this file is and what it does ? ===
   1.This is the slice for setting the user credentials to the localStorage 
   once authenticated and remove them 
   NB : It is not dealing with any api endpoints , it is only for the below two function
      * One is for setting the user credentials to the local storage once authenticated
      * The other is for removing all the users info in the local storage once the user is logged out
   */
  import { createSlice } from "@reduxjs/toolkit";
  
  // const initialState = {
  //   userInfo:localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')):null,
  // }
  const initialState = {
    token: localStorage.getItem('token') || null, // Retrieve token from LocalStorage if it exists
  };
  
  const authSlice = createSlice({
   name:'auth',
   initialState,
   reducers:{
    setCredentials:(state , action) =>{ 
      state.token = action.payload
      localStorage.setItem('token', action.payload);
    },
    logout:(state) =>{
         state.token=null
         localStorage.removeItem('token');
    }
   },
  })

export const {setCredentials ,logout} = authSlice.actions;

export default authSlice.reducer;
export const selectCurrentToken =(state) => state.auth.token;