import React from "react";
// import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

const InstructorRoute = () => {
    
  // const { userInfo } = useSelector((state) => state.auth);

  const instructor = true;

  return instructor ? <Outlet /> : <Navigate to="/" replace />;
};

export default InstructorRoute;
