import React from "react";
// import heroImg from "../../assets/img/heroImg.png";
import heroVideo from "../../assets/videos/hero-video.mov";

const Hero = () => {
  return (
    <div className="hero-video">
      <video autoPlay loop>
        <source src={heroVideo} />
      </video>
      <div className="hero-content ">
        <h1 className="fw-bolder">Welcome to Our E-Learning Platform</h1>
        <p>Unlock Your Potential, Learn Anything, Anytime</p>
        <a href="." className="btn" id="get-started-in-hero">
          Get Started
        </a>
      </div>
    </div>
  );
};

export default Hero;
