import React from "react";
import TeachWelcome from "../components/teachonus/TeachWelcome";
import Navbar from "../components/home/Navbar";
import Footer from "../components/home/Footer";

function TeachOnUs() {
  return (
    <div>
      <Navbar />
      <TeachWelcome />
      <Footer />
    </div>
  );
}

export default TeachOnUs;
