import React, { useState } from 'react';
import { ListGroup, Form, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import profile from "../assets/profile/user-1.jpg";


const DiscussionForum = () => {
    const [announcement, setAnnouncement] = useState('');
    const [announcements, setAnnouncements] = useState([]);

    const handleSendAnnouncement = (e) => {
        e.preventDefault();
        if (announcement.trim() !== '') {
            setAnnouncements([...announcements, announcement]);
            setAnnouncement('');
        }
    };
    const [questions, setQuestions] = useState([]);
    const [newQuestion, setNewQuestion] = useState('');

    const handleQuestionSubmit = (e) => {
        e.preventDefault();
        if (newQuestion.trim() !== '') {
            setQuestions([...questions, newQuestion]);
            setNewQuestion('');
        }
    };
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    
    console.log(setMessages)

    // const handleSendMessage = (e) => {
    //     e.preventDefault();
    //     if (message.trim() !== '') {
    //         setMessages([...messages, message]);
    //         setMessage('');
    //     }
    // };

    return (
        <div className="system-admin-dashboard bg-white">
            <div
                className="page-wrapper"
                id="main-wrapper"
                data-layout="vertical"
                data-navbarbg="skin6"
                data-sidebartype="full"
                data-sidebar-position="fixed"
                data-header-position="fixed"
            >
                {/* Sidebar Start */}
                <aside className="left-sidebar">
                    {/* Sidebar scroll*/}
                    <div>
                        <div className="brand-logo d-flex align-items-center justify-content-between">
                            <Link to='/instructor' className="text-nowrap logo-img">
                                <img src={logo} width={180} alt="Logoooo" />
                            </Link>
                            <div
                                className="close-btn d-xl-none d-block sidebartoggler cursor-pointer"
                                id="sidebarCollapse"
                            >
                                <i className="ti ti-x fs-8" />
                            </div>
                        </div>
                        {/* Sidebar navigation*/}
                        <nav className="sidebar-nav scroll-sidebar" data-simplebar>
                            <ul id="sidebarnav">
                                <li className="sidebar-item">
                                    <Link to='/instructor'
                                        className="sidebar-link"
                                        aria-expanded="false"
                                    >
                                        <span>
                                            <i className="ti ti-layout-dashboard" />
                                        </span>
                                        <span className="hide-menu">Home</span>
                                    </Link>
                                </li>
                                <li className="sidebar-item">
                                    <Link to='/course-management'
                                        className="sidebar-link"
                                        href="./ui-buttons.html"
                                        aria-expanded="false"
                                    >
                                        <span>
                                            <i className="ti ti-article" />
                                        </span>
                                        <span className="hide-menu">Course Management</span>
                                    </Link>
                                </li>
                                <li className="sidebar-item">
                                    <Link to='/course-analytics'
                                        className="sidebar-link"
                                        href="./ui-alerts.html"
                                        aria-expanded="false"
                                    >
                                        <span>
                                            <i className="ti ti-alert-circle" />
                                        </span>
                                        <span className="hide-menu">Course Analytics</span>
                                    </Link>
                                </li>
                                <li className="sidebar-item">
                                    <Link to='/discussion-forum'
                                        className="sidebar-link"
                                        href="./ui-alerts.html"
                                        aria-expanded="false"
                                    >
                                        <span>
                                            <i className="ti ti-alert-circle" />
                                        </span>
                                        <span className="hide-menu">Discussion Forum</span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                        {/* End Sidebar navigation */}
                    </div>
                    {/* End Sidebar scroll*/}
                </aside>
                {/*  Main wrapper */}
                <div className="body-wrapper">
                    {/*  Header Start */}
                    <header className="app-header">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <div
                                className="navbar-collapse justify-content-end px-0"
                                id="navbarNav"
                            >
                                <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                                    <li className="nav-item dropdown">
                                        <a
                                            className="nav-link nav-icon-hover"
                                            href="."
                                            id="drop2"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <img
                                                src={profile}
                                                alt=""
                                                width={35}
                                                height={35}
                                                className="rounded-circle"
                                            />
                                        </a>
                                        <div
                                            className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up"
                                            aria-labelledby="drop2"
                                        >
                                            <div className="message-body">
                                                <a
                                                    href="."
                                                    className="d-flex align-items-center gap-2 dropdown-item"
                                                >
                                                    <i className="ti ti-user fs-6" />
                                                    <p className="mb-0 fs-3">My Profile</p>
                                                </a>
                                                <a
                                                    href="./authentication-login.html"
                                                    className="btn btn-outline-primary mx-3 mt-2 d-block"
                                                >
                                                    Logout
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </header>
                    {/*  Header End */}
                    <div className="row">
                        <div className="col-lg-11 d-flex align-items-stretch">
                            <div className="card w-100">
                                <div className="card-body">
                                    <div className="mb-3 mb-sm-0" >
                                        <h5 className="card-title fw-semibold">Discussin Forum</h5>

                                    </div>
                                    <br />
                                    <Form onSubmit={handleQuestionSubmit}>
                                        <Form.Group controlId="questionForm">
                                            <Form.Label><i className='bi  bi-patch-question-fill fs-8 text-warning px-4 py-4' />Ask a Question:</Form.Label>
                                            <Form.Control rows={7}
                                                as="textarea"
                                                placeholder="Enter your question"
                                                value={newQuestion}
                                                onChange={(e) => setNewQuestion(e.target.value)}
                                                className='mb-2'
                                            />
                                        </Form.Group>
                                        <Button variant="outline-warning " style={{
                                            position: 'absolute',
                                            right: '0' }}
                                             type="submit">
                                            Submit
                                        </Button>
                                        <Form.Group controlId="messageForm">
                                            <Form.Label><i className='bi  bi-send-fill fs-8 text-warning px-4 py-4' />Send a message:</Form.Label>
                                            <Form.Control
                                                className='mb-2'
                                                as="textarea"
                                                rows={3}
                                                placeholder="Enter your message"
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Button variant="outline-warning " style={{
                                            position: 'absolute',
                                            right: '2' }} type="submit">
                                            Send
                                        </Button>
                                        <ListGroup>
                                            {messages.map((msg, index) => (
                                                <ListGroup.Item key={index}>{msg}</ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                        <Form onSubmit={handleSendAnnouncement}>
                                            <Form.Group controlId="announcementForm">
                                                <Form.Label><i className='bi  bi-megaphone-fill fs-8 text-warning px-4 py-4' />Send an announcement:</Form.Label>
                                                <Form.Control
                                                    className='mb-2'
                                                    as="textarea"
                                                    rows={3}
                                                    placeholder="Enter your announcement"
                                                    value={announcement}
                                                    onChange={(e) => setAnnouncement(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Button variant="outline-warning" style={{
                                            position: 'absolute',
                                            right: '0' }} 
                                            type="submit">
                                                Send
                                            </Button>
                                        </Form>
                                        <hr />
                                        <ListGroup>
                                            {announcements.map((msg, index) => (
                                                <ListGroup.Item key={index}>{msg}</ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    </Form>



                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default DiscussionForum;