import React from "react";
import whywinImg from "../../assets/img/vector2.jpg";

const WhyWin = () => {
  return (
<section className="why-we-win-section">
    <div className="container">
        <div className="row">
        <h1 className="text-center p-3">Why We Win</h1>
            <div className="col-md-6">
                <div className="reasons mt-5 p-1">
                    <ul>
                        <li>Quality Content</li>
                        <li>Expert Instructors</li>
                        <li>Flexible Learning Options</li>
                        <li>Community Support</li>
                    </ul>
                </div>
            </div>
            <div className="col-md-6 p-1">
                <div className="illustration">
                    <img src={whywinImg} alt="E-Learning Illustration"/>
                </div>
            </div>
        </div>
    </div>
</section>

  );
};

export default WhyWin;
