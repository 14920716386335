
import { initializeApp } from "firebase/app";
import {getStorage} from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyDfZeLW1T68r-w0RD4PVa4XdeHt-oVwAXc",
  authDomain: "u-learnfiles.firebaseapp.com",
  projectId: "u-learnfiles",
  storageBucket: "u-learnfiles.appspot.com",
  messagingSenderId: "440234000399",
  appId: "1:440234000399:web:3b24c4d105e68c5d94637a"
};


const app = initializeApp(firebaseConfig);

export const storage = getStorage(app)