import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : { coursesInCart: [] };

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const course = action.payload;

      const existCourse = state.coursesInCart.find((x) => x.id === course.id);

      if (existCourse) {
        state.coursesInCart = state.coursesInCart.map((x) =>
          x.id === existCourse.id ? course : x
        );
      } else {
        state.coursesInCart = [...state.coursesInCart, course];
      }
      // Set te cart state to the local storage
      localStorage.setItem("cart", JSON.stringify(state));
    },
    removeFromCart: (state, action) => {
      state.coursesInCart = state.coursesInCart.filter(
        (x) => x.id !== action.payload
      );
      // Set the updated cart state to the local storage
      localStorage.setItem("cart", JSON.stringify(state));
    },
  },
});

export const { addToCart, removeFromCart } = cartSlice.actions;

export default cartSlice.reducer;
